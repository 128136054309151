import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import SharedContextProvider from "./Context/SharedContextProvider/SharedContextProvider";
import AppointmentsContextProvider from "./Context/AppointmentsContextProvider/AppointmentsContextProvider";
import ViewAppointmentContextProvider from "./Context/ViewAppointmentContextProvider/ViewAppointmentContextProvider";
import PatientsContextProvider from "./Context/PatientsContextProvider/PatientsContextProvider";

import "react-calendar/dist/Calendar.css";
import ProfileContextProvider from "./Context/ProfileContextProvider/ProfileContextProvider";
import SignupContextProvider from "./Context/SignupContextProvider/SignupContextProvider";
import CustomFunctionsContextProvider from "./Context/CustomFunctionsContextProvider/CustomFunctionsContextProvider";
import CustomHooksContextProvider from "./Context/CustomHooksContextProvider/CustomHooksContextProvider";
import SettingsContextProvider from "./Context/SettingsContextProvider/SettingsContextProvider";
import AppContextProvider from "./Context/AppContext/AppContextProvider";
import LoginContextProvider from "./Context/LoginContextProvider/LoginContextProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContextProvider>
        <SharedContextProvider>
          <CustomFunctionsContextProvider>
            <CustomHooksContextProvider>
              <SettingsContextProvider>
                <SignupContextProvider>
                  <LoginContextProvider>
                    <AppointmentsContextProvider>
                      <ViewAppointmentContextProvider>
                        <PatientsContextProvider>
                          <ProfileContextProvider>
                            <App />
                          </ProfileContextProvider>
                        </PatientsContextProvider>
                      </ViewAppointmentContextProvider>
                    </AppointmentsContextProvider>
                  </LoginContextProvider>
                </SignupContextProvider>
              </SettingsContextProvider>
            </CustomHooksContextProvider>
          </CustomFunctionsContextProvider>
        </SharedContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
