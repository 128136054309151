import React, { createContext } from 'react'

export const PatientsContext = createContext()

const PatientsContextProvider = ({ children }) => {

    const values = {

    }
    return (
        <PatientsContext.Provider value={values}>
            {children}
        </PatientsContext.Provider>
    )
}

export default PatientsContextProvider
