import React, { useContext } from 'react'
import { Button, Tooltip } from 'antd';
// import { FileExcelOutlined } from '@ant-design/icons'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { SharedContext } from '../../../Context/SharedContextProvider/SharedContextProvider';

export const ExportCSV = ({ csvData, fileName }) => {
    const { excel_icon } = useContext(SharedContext)

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Tooltip title='Export to Excel'>

            <Button
                style={{ background: "var(--colorWhiteType2)", display: "flex", alignItems: "center" }}
                type='text'
                icon={<img src={excel_icon} alt="" style={{ width: "20px", background: "var(--colorWhiteType2)" }} />}
                onClick={(e) => exportToCSV(csvData, fileName)}
            >Export</Button>
        </Tooltip>
    )
}