import React, { createContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    numberOfDaysBetweenTwoDates,
    formatHumanReadableDate,
    getFutureDate,
    getPreviousDate,
} from './functions/dates'

import { formatCurrency } from './functions/formatCurrency'
import { capitalizeFirstLetter } from './functions/capitalizeFirstLetter'
import testImage from '../../Assets/testImage/testImage.jpg'
import blank_user from '../../Assets/testImage/blank_user.jpeg'
import patternBG from '../../Assets/patterns/R.jpeg'
import logo from '../../Assets/logo/favicon.svg'
import logo_2 from '../../Assets/logo/nmh-1.png'
import excel_icon from '../../Assets/icons/excel2-svgrepo-com.svg'

import { intlFormatDistance, differenceInYears } from "date-fns";
import useDate from './customFunctions/useDate'
import { specializations } from './data/specializations'
import { CONSTANTS } from './constants'
import { notification } from 'antd';
// 
import doctorImage from '../../Assets/MeetOurSpecialists/doc2.jpg'

export const SharedContext = createContext()

notification.config({
    placement: 'bottomLeft',
    top: 50,
    duration: 5,
    maxCount: 3,
})

const SharedContextProvider = ({ children }) => {
    const navigate = useNavigate()


    const values = {
        CONSTANTS,
        notification,
        // 
        navigate,
        logo,
        logo_2,
        doctorImage,
        excel_icon,
        blank_user,
        // 
        numberOfDaysBetweenTwoDates,
        formatHumanReadableDate,
        getFutureDate,
        getPreviousDate,
        //
        formatCurrency,
        // 
        capitalizeFirstLetter,
        testImage,
        patternBG,
        intlFormatDistance,
        differenceInYears,
        // 
        useDate,
        specializations
    }
    return (
        <SharedContext.Provider value={values}>
            {children}
        </SharedContext.Provider>
    )
}

export default SharedContextProvider
