import React, { useContext, } from 'react'
import '../SignUp.css'
// import { UserOutlined, MailOutlined, DashboardFilled } from '@ant-design/icons'
import { Button, Form, Input, Select } from 'antd'
import { motion } from "framer-motion"
import { SignupContext } from "../../../Context/SignupContextProvider/SignupContextProvider";
// import { SettingsContext } from '../../../Context/SettingsContextProvider/SettingsContextProvider';
// import { CustomFunctionsContext } from '../../../Context/CustomFunctionsContextProvider/CustomFunctionsContextProvider';
// const { Option } = Select;


const Details = () => {

    const {
        // 
        // createData,
        setCreateData,
        // 
        // 
        handleSignup,
    } = useContext(SignupContext)

    // const {
    //     getSpecializations
    // } = useContext(SettingsContext)

    // const { handleHTTPErrors, transformSpecialization } = useContext(CustomFunctionsContext);

    // const [specializationList, setSpecializationList] = useState([])

    // useEffect(() => {
    //     async function fxn() {
    //         try {
    //             const response = await getSpecializations();
    //             if (response.status === 200) {
    //                 setSpecializationList(response.data.data)
    //             }
    //         } catch (error) {
    //             console.log(error)
    //             handleHTTPErrors(error);
    //         }
    //     }
    //     // fxn()

    //     // eslint-disable-next-line
    // }, [])

    return (
        <motion.div
            className="details_wrapper"

            initial={{ opacity: 0, translateY: "-25px", }}
            animate={{ opacity: 1, translateY: "0", }}
            transition={{ duration: 0.5 }}
        >
            <h1 className="row_1">
                SignUp
            </h1>
            <p className="subtext">Complete the fields provided below</p>

            <Form
                name='signupForm'
                layout="vertical"

            // onFinish={handleSignup}
            >
                <div className="form_grp">
                    <div className="grid_x2">
                        <Form.Item
                            label="Prefix?"
                            name='prefix'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select placeholder="prefix"
                                onChange={(e) => setCreateData(prev => ({ ...prev, prefix: e }))}
                                options={[
                                    { label: "Dr. (Medical Doctor) ", value: "Dr." },
                                    { label: "RN. (Registered Nurse) ", value: "Rn." },
                                ]}
                            />
                        </Form.Item>

                        {/* <Form.Item
                            label="Specialization"
                            name='specialization'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select placeholder="Specialization"
                                onChange={(e) => setCreateData(prev => ({ ...prev, prefix: e }))}
                                options={transformSpecialization(specializationList)}
                            />
                        </Form.Item> */}
                    </div>

                    <div className="grid_x2">
                        <Form.Item label="First name"
                            required
                            name='firstName'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}

                        >
                            <Input placeholder="First name"
                                onChange={(e) => setCreateData(prev => ({ ...prev, first_name: e.target.value }))}
                            />
                        </Form.Item>
                        <Form.Item
                            name='lastName'
                            label="Last name"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input placeholder="Last name"
                                onChange={(e) => setCreateData(prev => ({ ...prev, last_name: e.target.value }))}
                            />
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            label="Gender"
                            name='gender'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select placeholder="Gender"
                                onChange={(e) => setCreateData(prev => ({ ...prev, gender: e }))}
                                options={[
                                    { label: "Male", value: "male" },
                                    { label: "Female", value: "female" },
                                ]}
                            />
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            name='email'
                            label="Email"
                            required
                            rules={[
                                {
                                    type: 'email',
                                    required: true
                                },
                            ]}
                        >
                            <Input placeholder="example@mail.com"
                                onChange={(e) => setCreateData(prev => ({ ...prev, email: e.target.value }))}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Phone"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            name='phone'
                        >
                            <Input placeholder="Phone"
                                onChange={(e) => setCreateData(prev => ({ ...prev, phone_1: e.target.value }))}
                            />
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type='password' placeholder="Password" name='password'
                                onChange={(e) => setCreateData(prev => ({ ...prev, password: e.target.value }))}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Confirm Password"
                            name="password2"
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input type='password' placeholder="ConfirmPassword" name='password2' />
                        </Form.Item>
                    </div>

                    <div className="grid_x2">
                        <Form.Item
                            label="Where did you hear about us?"
                            name='whereDidYouHearAboutUs'
                        // rules={[
                        //     {
                        //         required: true,
                        //     },
                        // ]}
                        >
                            <Select placeholder="Where did you hear about us? (optional)"
                                onChange={(e) => setCreateData(prev => ({ ...prev, where_did_you_hear_about_us: e }))}
                                options={[
                                    { label: "TV", value: "tv" },
                                    { label: "Radio", value: "radio" },
                                    { label: "Social Media", value: "social_media" },
                                    { label: "Internet", value: "Internet" },
                                    { label: "Blog", value: "blog" },
                                    { label: "Others", value: "others" },
                                ]}
                            />
                        </Form.Item>
                    </div>

                    <div className="btn_grp">
                        <Button
                            // htmlType='submit'
                            // type="primary"
                            // size='large'
                            // className='btnType3'
                            onClick={handleSignup}
                        // style={{ justifySelf: "flex-end" }}
                        // onLoad={true}
                        // loading={loading}
                        >
                            Create
                        </Button>
                    </div>
                </div>

            </Form>
        </motion.div>
    )
}



export default Details
