import React, { createContext, useState } from 'react'
// import { SharedContext } from '../SharedContextProvider/SharedContextProvider';

export const ViewAppointmentContext = createContext()
const ViewAppointmentContextProvider = ({ children }) => {
    // const { navigate, capitalizeFirstLetter } = useContext(SharedContext)

    const [consultationStatus, setConsultationStatus] = useState(true);


    // ADD PRESCRIPTION MODAL
    const [isModalOpen_AddPrescription, setIsModalOpen_AddPrescription] = useState(false);
    const showModal_AddPrescription = () => {
        setIsModalOpen_AddPrescription(true);
    };
    const handleOk_AddPrescription = () => {
        setIsModalOpen_AddPrescription(false);
    };
    const handleCancel_AddPrescription = () => {
        setIsModalOpen_AddPrescription(false);
    };


    // Open Patients EMR
    function handleOpenPatientsEMR(docId, patId) {
        let width = '1400';
        let height = 500;

        // Calculate the position of the new window to be in the center of the screen
        let left = (window.innerWidth / 2) - (width / 2);
        let top = (window.innerHeight / 2) - (height / 2);
        window.open(`${process.env.REACT_APP_NMH_EMR_URL}/records?u_type=doctor&u_id=${docId}&p_id=${patId}`, "_blank", `toolbar=no, width=${width}, height=${height}, top=${top}, left=${left}`)
    }

    // start consultation
    function handleStartConsultation(aptId, docId, patId) {
        let width = '1400';
        let height = 500;

        // Calculate the position of the new window to be in the center of the screen
        let left = (window.innerWidth / 2) - (width / 2);
        let top = (window.innerHeight / 2) - (height / 2);
        setConsultationStatus(!consultationStatus)
        window.open(`/consultation-session/${aptId}/${docId}/${patId}`, "_blank", `toolbar=no, width=${width}, height=${height}, top=${top}, left=${left}, title=consultation session`)
    }

    // end consultation
    function handleEndConsultation(aptId, docId, patId) {
        setConsultationStatus(!consultationStatus)
    }


    const values = {
        isModalOpen_AddPrescription,
        showModal_AddPrescription,
        handleOk_AddPrescription,
        handleCancel_AddPrescription,
        // 
        consultationStatus,
        handleOpenPatientsEMR,
        handleStartConsultation,
        handleEndConsultation
    }
    return (
        <ViewAppointmentContext.Provider value={values}>
            {children}
        </ViewAppointmentContext.Provider>
    )
}

export default ViewAppointmentContextProvider
