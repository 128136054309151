import React, { useContext, } from 'react'
import '../SignUp.css'
// import { UserOutlined, MailOutlined, DashboardFilled } from '@ant-design/icons'
import { Button, } from 'antd'
import { motion } from "framer-motion"
// import { SharedContext } from "../../../Context/SharedContextProvider/SharedContextProvider";
import { SignupContext } from "../../../Context/SignupContextProvider/SignupContextProvider";
// const { Option } = Select;



const Welcome = () => {

    // const { navigate } = useContext(SharedContext)

    const {
        // 
        // currentStep,
        // setCurrentStep,
        // // 
        // userType,
        // setUserType,
        // // 
        // message,
        // setMessage,
        // // 
        // loading,
        // setLoading,
        // // 
        // handleSignup,
        // handleVerifyEmail,
        handleNavigateToDashboard,
    } = useContext(SignupContext)

    return (
        <motion.div
            className="otp_wrapper"

        // initial={{ opacity: 0, scale: 0.5, }}
        // animate={{ opacity: 1, scale: 1 }}
        // transition={{ duration: 0.5 }}
        >
            <h1 className="row_1">
                Welcome to Neomerit Metro Health
            </h1>
            <p className="subtext">
                Get Up And Running In 2minutes
            </p>

            <div className="form_grp">
                <Button type="primary" size='large' className='btnType3' onClick={handleNavigateToDashboard}>Go To Dashboard</Button>
            </div>


        </motion.div>
    )
}

export default Welcome
