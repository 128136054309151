import React, { useContext, useEffect, useState } from 'react'
import { OrderedListOutlined, BorderOutlined, SortAscendingOutlined, CloseOutlined, SortDescendingOutlined, EyeOutlined, ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons'
import { Button, Input, Tooltip } from 'antd'
import test from '../../../Assets/MeetOurSpecialists/doc2.jpg'
import { SharedContext } from '../../../Context/SharedContextProvider/SharedContextProvider'
import { AppointmentsContext } from '../../../Context/AppointmentsContextProvider/AppointmentsContextProvider'
import { motion } from "framer-motion"
import AppointmentsTable from './AppointmentsTable/AppointmentsTable'
import { SettingsContext } from '../../../Context/SettingsContextProvider/SettingsContextProvider'
import { AppContext } from '../../../Context/AppContext/AppContextProvider'
import { CustomFunctionsContext } from '../../../Context/CustomFunctionsContextProvider/CustomFunctionsContextProvider'
// import { SettingsContext } from '../../../Context/SettingsContextProvider/SettingsContextProvider'
// import { CustomHooksContext } from '../../../Context/CustomHooksContextProvider/CustomHooksContextProvider'


const Appointments = () => {
    const {
        searchValue,
        setSearchValue,
        // 
        appointments,
        setAppointments,
        filteredAppointments,
        // searchByDate,
        searchByPatient,
        sortByDate,
        dateSorted,
        resetFilter
    } = useContext(AppointmentsContext)
    const { formatHumanReadableDate, navigate, capitalizeFirstLetter } = useContext(SharedContext)

    const { getDoctorAppointments } = useContext(SettingsContext)
    // const { useGetRequest, } = useContext(CustomHooksContext)
    const { showGlobalLoading, hideGlobalLoading, } = useContext(AppContext);
    const { handleHTTPErrors, } = useContext(CustomFunctionsContext);

    // const { getDoctorAppointments } = useContext(SettingsContext)
    // const { useGetRequest, } = useContext(CustomHooksContext)

    const [isAtTop, setIsAtTop] = useState(true);
    const [viewType, setViewType] = useState(true);


    // useGetRequest(getDoctorAppointments, setAppointments);


    const handleScroll = () => {
        if (window.scrollY === 0) {
            setIsAtTop(true);
        } else {
            setIsAtTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSearch = (e) => {
        setSearchValue(e.target.value)
        searchByPatient(searchValue)
    }

    function handleViewType() {
        setViewType(!viewType);
    }

    useEffect(() => {
        async function fxn() {
            try {
                showGlobalLoading();
                const response = await getDoctorAppointments();

                if (response.status === 200) {
                    setAppointments(response.data.data)
                }
                hideGlobalLoading();
            } catch (error) {
                console.log(error)
                hideGlobalLoading();
                handleHTTPErrors(error);
            }
        }
        fxn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            className='general_frame_wrapper'
        >
            <div className="inner__">

                <div className={`${!isAtTop ? "heading_bar" : "heading_bar_2"}`}>
                    <motion.h1
                        style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                        initial={{ opacity: 0, translateY: "-10px", }}
                        animate={{ opacity: 1, translateY: "0", }}
                        transition={{ duration: 0.5 }}
                    >
                        {/* <Button icon={<ArrowLeftOutlined />} type='text' size='large' onClick={() => navigate('/appointments')} /> */}
                        <span>
                            <CalendarOutlined /> Appointments ({appointments.length})
                        </span>
                    </motion.h1>
                    <div className="filter_bar">
                        <div className="filter_btns">
                            {
                                !viewType === true
                                    ?
                                    <Tooltip title='List View'> <Button icon={<OrderedListOutlined />} type='text' onClick={handleViewType} /> </Tooltip>
                                    :
                                    <Tooltip title='Grid View'> <Button icon={<BorderOutlined />} type='text' onClick={handleViewType} /> </Tooltip>
                            }
                        </div>
                        <div className="search_grp">
                            <Input
                                type="search"
                                id=""
                                placeholder='Search Patient Name'
                                value={searchValue}
                                autoFocus
                                onChange={handleSearch}
                            />
                        </div>
                        <div className="filter_btns">
                            <Tooltip title='Clear search'> <Button icon={<CloseOutlined />} type='text' onClick={resetFilter} /> </Tooltip>
                            {/* <Tooltip title='Clear search'> <Button icon={<FilterOutlined />} type='text' />  </Tooltip> */}
                            {
                                dateSorted ?
                                    <Tooltip title='Sort by date'> <Button icon={<SortAscendingOutlined />} type='text' onClick={sortByDate} />  </Tooltip>
                                    :
                                    <Tooltip title='Sort by date'> <Button icon={<SortDescendingOutlined />} type='text' onClick={sortByDate} />  </Tooltip>
                            }
                        </div>
                    </div>
                </div >

                {
                    viewType === true
                        ?
                        <AppointmentsTable data={appointments} />
                        :
                        <motion.div
                            className="grid_grp"

                            initial={{ opacity: 0, translateX: "15px", width: "80%" }}
                            animate={{ opacity: 1, translateX: "0", width: "" }}
                            transition={{ duration: 0.5 }}
                        >
                            {
                                filteredAppointments.length > 0 && filteredAppointments.map((item, index) => (
                                    <div className="grid_item" key={item.id}>
                                        <div className="info_box">
                                            <div className="">
                                                <div className="name">{capitalizeFirstLetter(item.patientName)}</div>
                                                <div className="condition">{capitalizeFirstLetter(item.reason)}</div>
                                            </div>
                                            <div className="date_time">
                                                <span className="date"> <CalendarOutlined /> {formatHumanReadableDate(item.appointmentDate)} </span>
                                                <span className="time"> <ClockCircleOutlined /> {item.appointmentTime} </span>
                                            </div>
                                            <div className="controls_box">
                                                {/* <Button icon={<MessageOutlined />} type='text' onClick={() => navigate('/chat/13')} > Chat </Button> */}
                                                <Button icon={<EyeOutlined />} type='text' onClick={() => navigate(`/appointments/${item.appointmentId}`)}  >View Appointment</Button>
                                            </div>
                                        </div>
                                        <div className="image_box">
                                            <img src={item.patientAvatar ? item.patientAvatar : test} alt="" />
                                        </div>
                                    </div>
                                ))
                            }
                        </motion.div>

                }



            </div >
        </div >
    )
}

export default Appointments
