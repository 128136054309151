import React, { createContext, useContext, useState } from 'react'
import EditProfileContextProvider from './EditProfileContextProvider/EditProfileContextProvider';
import { SettingsContext } from '../SettingsContextProvider/SettingsContextProvider';
import { message } from 'antd';
import { SharedContext } from '../SharedContextProvider/SharedContextProvider';
import { AppContext } from '../AppContext/AppContextProvider';
import { CustomFunctionsContext } from '../CustomFunctionsContextProvider/CustomFunctionsContextProvider';

export const ProfileContext = createContext()

const ProfileContextProvider = ({ children }) => {


    const { notification } = useContext(SharedContext)
    const { showGlobalLoading, hideGlobalLoading } = useContext(AppContext)
    const {
        changeProfilePhoto,
        getDoctorProfile,
        // 
        updateProfileGrpA,
        updateProfileGrpB,
        updateProfileGrpC,
        updateProfileGrpD,
        updateProfileGrpE,
        updateProfileGrpF,
        // 
    } = useContext(SettingsContext)
    // const { useGetRequest, } = useContext(CustomHooksContext)
    const { handleHTTPErrors, } = useContext(CustomFunctionsContext)

    // -------------------------------------------------
    const [myProfile, setMyProfile] = useState(null);

    const [open_create, setOpen_create] = useState(false);
    const [createData, setCreateData] = useState({
        avatar: null,
    })

    const showDrawer_create = () => {
        setOpen_create(true);
    };
    const onClose_create = () => {
        setOpen_create(false);
    };

    async function handleCreateSuccess() {
        try {
            showGlobalLoading();
            const response = await getDoctorProfile();

            if (response.status === 200) {
                message.success(response.data.message);
                localStorage.setItem('NMH_USER_DATA', JSON.stringify(response.data.data))
            }
            hideGlobalLoading();
        } catch (error) {
            hideGlobalLoading();
            handleHTTPErrors(error);
        }
    }

    async function handleUpload() {
        if (Object.values(createData).includes(null)) {
            notification.error({
                message: "No file selected!",
            })
            return false
        }

        console.log(createData.avatar)

        try {
            showGlobalLoading();
            const response = await changeProfilePhoto(createData.avatar);

            console.log(response)

            if (response.status === 200) {
                message.success(response.data.message);

                // notify success
                notification.success({
                    message: response.data.message,
                })
                onClose_create()

                handleCreateSuccess()
            }
            hideGlobalLoading();
        } catch (error) {
            console.log(error)
            hideGlobalLoading();
            handleHTTPErrors(error);
        }
    }
    // --------------------------
    async function handleSubmitFormData_A(data) {
        let d = {
            ...data,
            first_name: data?.firstName,
            middle_name: data?.middleName,
            last_name: data?.lastName,
            phone_1: data?.phone1,
            phone_2: data?.phone2,
        }
        try {
            const response = await updateProfileGrpA(d);
            if (response.status === 200) {
                notification.success({
                    message: response.data.message,
                })
            }
        } catch (error) {
            console.log(error)
            handleHTTPErrors(error);
        }
    }

    async function handleSubmitFormData_B(data) {
        let d = {
            ...data,
            specialization: data?.specializationId,
            years_of_experience: data?.yearsOfExperience,
            license_number: data?.licenseNumber,
        }
        try {
            const response = await updateProfileGrpB(d);
            if (response.status === 200) {
                notification.success({
                    message: response.data.message,
                })
            }
        } catch (error) {
            console.log(error)
            handleHTTPErrors(error);
        }
    }

    async function handleSubmitFormData_C(data) {
        try {
            const response = await updateProfileGrpC(data);
            if (response.status === 200) {
                notification.success({
                    message: response.data.message,
                })
            }
        } catch (error) {
            console.log(error)
            handleHTTPErrors(error);
        }
    }

    async function handleSubmitFormData_D(data) {
        try {
            const response = await updateProfileGrpD(data);
            if (response.status === 200) {
                notification.success({
                    message: response.data.message,
                })
            }
        } catch (error) {
            console.log(error)
            handleHTTPErrors(error);
        }
    }

    async function handleSubmitFormData_E(data) {
        try {
            const response = await updateProfileGrpE(data);
            if (response.status === 200) {
                notification.success({
                    message: response.data.message,
                })
            }
        } catch (error) {
            console.log(error)
            handleHTTPErrors(error);
        }
    }

    async function handleSubmitFormData_F(data) {
        try {
            const response = await updateProfileGrpF(data);
            if (response.status === 200) {
                notification.success({
                    message: response.data.message,
                })
            }
        } catch (error) {
            console.log(error)
            handleHTTPErrors(error);
        }
    }


    const values = {
        createData, setCreateData,
        // 
        open_create,
        showDrawer_create,
        onClose_create,
        // 
        handleUpload,
        // 
        myProfile, setMyProfile,
        // 
        handleSubmitFormData_A,
        handleSubmitFormData_B,
        handleSubmitFormData_C,
        handleSubmitFormData_D,
        handleSubmitFormData_E,
        handleSubmitFormData_F,
        // 
    }

    return (
        <ProfileContext.Provider value={values}>
            <EditProfileContextProvider>
                {children}
            </EditProfileContextProvider>
        </ProfileContext.Provider>
    )
}

export default ProfileContextProvider
