// const CONSTANTS = {
//   FRONTEND_URL:
//     process.env.NODE_ENV === "production"
//       ? process.env.REACT_APP_REMOTE_FRONTEND_URL
//       : process.env.REACT_APP_LOCAL_FRONTEND_URL,
//   BACKEND_URL:
//     process.env.NODE_ENV === "production"
//       ? process.env.REACT_APP_NMH_API_URL
//       : process.env.REACT_APP_NMH_LOCAL_API_URL,
// };
const CONSTANTS = {
  FRONTEND_URL:
    process.env.NODE_ENV === "production"
      ? "https://neomeritmetrohealth.com"
      : process.env.REACT_APP_LOCAL_FRONTEND_URL,
  BACKEND_URL:
    process.env.NODE_ENV === "production"
      ? "https://server.neomeritmetrohealth.com/api/v1"
      : "http://localhost:5000/api/v1",
};

module.exports = { CONSTANTS };
