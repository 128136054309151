// import { useContext, useEffect } from "react";
import { message } from "antd";
import { clearCredentialsAndRedirectToLoginPage } from "./clearCredentialsAndRedirectToLoginPage";
// import { AppContext } from "../AppContext/AppContextProvider";
// import { AuthContext } from "../AuthContextProvider/AuthContextProvider";

async function getRequest(getRequestFunction, payload, setDataFunction) {
  //   const { showGlobalLoading, hideGlobalLoading } = useContext(AppContext);
  //   const { clearCredentialsAndRedirectToLoginPage } = useContext(AuthContext);

  try {
    // showGlobalLoading();
    const response = await getRequestFunction(payload ? payload : null);

    if (response.status === 200) {
      message.success(response.data.message);
      setDataFunction(response.data.data);
    }
    // hideGlobalLoading();
  } catch (error) {
    // hideGlobalLoading();
    if (error.response.status === 422) {
      message.error(error.response.data.message);
    }
    if (error.response.status === 429) {
      message.error(error.response.data.message);
    }
    if (error.response.status === 401) {
      message.error(error.response.data.message);
      clearCredentialsAndRedirectToLoginPage();
    }
  }
}

export default getRequest;
