import React, { useContext, useEffect, useState } from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import './DoctorsDashboard.css'
import { BellFilled, MessageFilled, UserOutlined, EditOutlined, PoweroffOutlined, AppstoreOutlined, MessageOutlined, CalendarOutlined, } from '@ant-design/icons'
import { Button, FloatButton, Image, Tooltip } from 'antd'
import test from '../../Assets/MeetOurSpecialists/doc2.jpg'
import Appointments from './Appointments/Appointments'
import Patients from './Patients/Patients'
import Chats from './Chats/Chats'
// import Profile from './Profile/Profile'
import ViewAppoinment from './Appointments/ViewAppoinment/ViewAppoinment'
import { SharedContext } from '../../Context/SharedContextProvider/SharedContextProvider'
import Breadcrumbs from './Breadcrumbs/Breadcrumbs'
import Dashboard from './Dashboard/Dashboard'
import EditProfile from './Profile/EditProfile/EditProfile'
import NotFound from '../NotFound/NotFound'
import { AppContext } from '../../Context/AppContext/AppContextProvider'
import { LoginContext } from '../../Context/LoginContextProvider/LoginContextProvider'
import EditAvatar from './Profile/EditAvatar/EditAvatar'
import { ProfileContext } from '../../Context/ProfileContextProvider/ProfileContextProvider'
// import ConsultationSession from './Appointments/ConsultationSession/ConsultationSession'

const navLinks = [
    { path: "/", label: "Dashboard", icon: AppstoreOutlined, description: "Dashboard" },
    { path: "/appointments", label: "Appointments", icon: CalendarOutlined, description: "See and manage all upcoming appointments" },
    { path: "/patients", label: "Patients", icon: UserOutlined, description: "See and manage all your Patients" },
    { path: "/chats", label: "Chats", icon: MessageOutlined, description: "Chats with Patients and other Doctors" },
    // { path: "/requests", label: "Requests", icon: FolderOpenOutlined, description: "See patients requesting your service" },
]

const DoctorsDashboard = () => {
    const {
        // createData, setCreateData,
        // 
        // open_create,
        showDrawer_create,
        // onClose_create,
        // 
        // handleUpload
    } = useContext(ProfileContext)

    const { logo_2, useDate } = useContext(SharedContext)
    const { appState, setAppState } = useContext(AppContext)
    const { handleLogout } = useContext(LoginContext)

    const { date, time, } = useDate();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    function toggleSidebar() {
        setIsSidebarOpen(!isSidebarOpen)
    }


    useEffect(() => {
        if (localStorage.getItem("NMH_USER_DATA")) {
            function GET_SESSION() {
                // fetch the user data saved in local storage
                const USER_DATA = JSON.parse(localStorage.getItem("NMH_USER_DATA"))

                setAppState((prev) => ({
                    ...prev,
                    isLoading: false,

                    prefix: USER_DATA?.prefix,
                    first_name: USER_DATA?.first_name,
                    last_name: USER_DATA?.last_name,
                    middle_name: USER_DATA?.middle_name,
                    gender: USER_DATA?.gender,
                    email: USER_DATA?.email,
                    phone_1: USER_DATA?.phone_1,
                    phone_2: USER_DATA?.phone_2,
                    verified: USER_DATA?.verified,
                    created: USER_DATA?.created,
                    avatar: USER_DATA?.avatar,
                    where_did_you_hear_about_us: USER_DATA?.where_did_you_hear_about_us,
                    user_type: USER_DATA?.user_type,
                    deleted_at: USER_DATA?.deleted_at,

                    bio: USER_DATA?.bio,
                    last_seen: USER_DATA?.last_seen,
                    specialization: USER_DATA?.specialization_type,
                    years_of_experience: USER_DATA?.years_of_experience,
                    license_number: USER_DATA?.license_number,
                    address: USER_DATA?.address,

                    affiliation1: USER_DATA?.affiliation1,
                    affiliation2: USER_DATA?.affiliation2,

                    mon_from: USER_DATA?.mon_from,
                    mon_to: USER_DATA?.mon_to,
                    tue_from: USER_DATA?.tue_from,
                    tue_to: USER_DATA?.tue_to,
                    wed_from: USER_DATA?.wed_from,
                    wed_to: USER_DATA?.wed_to,
                    thu_from: USER_DATA?.thu_from,
                    thu_to: USER_DATA?.thu_to,
                    fri_from: USER_DATA?.fri_from,
                    fri_to: USER_DATA?.fri_to,
                    sat_from: USER_DATA?.sat_from,
                    sat_to: USER_DATA?.sat_to,
                    sun_from: USER_DATA?.sun_from,
                    sun_to: USER_DATA?.sun_to,

                    lang1: USER_DATA?.lang1,
                    lang2: USER_DATA?.lang2,

                    q1: USER_DATA?.q1,
                    q2: USER_DATA?.q2,
                    q3: USER_DATA?.q3,
                    q4: USER_DATA?.q4,

                    overall_rating: USER_DATA?.overall_rating,
                    total_reviews: USER_DATA?.total_reviews,
                    total_reviewing_patients: USER_DATA?.total_reviewing_patients,
                    total_patients: USER_DATA?.total_patients,

                    total_appointments: USER_DATA?.total_appointments,
                    upcoming_appointments: USER_DATA?.upcoming_appointments,
                    cancelled_appointments: USER_DATA?.cancelled_appointments,
                    completed_appointments: USER_DATA?.completed_appointments,
                    pending_appointments: USER_DATA?.pending_appointments,
                    missed_appointments: USER_DATA?.missed_appointments,

                    comments: USER_DATA?.comments,
                }))
            }
            GET_SESSION()
        }
        // return () => {
        //     localStorage.removeItem("NMH_USER_DATA")
        // }
    }, [setAppState])

    return (
        <div className='DoctorsDashboard_wrapper'>
            <div className={`sidebar_wrapper ${isSidebarOpen ? "sidebar_wrapper_opned" : "sidebar_wrapper_closed"}`}>
                <div className="inner__">

                    <Link to='/'>
                        <div className="box_4">
                            <img src={logo_2} alt="" />
                        </div>
                    </Link>

                    <div className="box_1">
                        <div className="image_box">
                            {appState.avatar
                                ?
                                <div className="">
                                    <div className="">
                                        <Image src={appState.avatar} />
                                    </div>
                                    <Tooltip title='Change Profile Photo' placement='right'>
                                        <Button icon={<EditOutlined />}
                                            type='text'
                                            size='large'
                                            className='change_avatar_btn'
                                            onClick={showDrawer_create}
                                        >

                                        </Button>
                                    </Tooltip>
                                </div>
                                :

                                <div className="">
                                    <div className="">
                                        <Image src={test} />
                                    </div>
                                    <Tooltip title='Change Profile Photo' placement='right'>
                                        <Button icon={<EditOutlined />}
                                            type='text'
                                            size='large'
                                            className='change_avatar_btn'
                                            onClick={showDrawer_create}
                                        >

                                        </Button>
                                    </Tooltip>
                                </div>
                            }
                        </div>
                        <h1 className="name_box">
                            {appState?.prefix ?? "--"} {appState?.first_name ?? "--"}   {appState?.last_name ?? "--"}
                        </h1>
                        <p className="specialization_box">  {appState?.specialization ?? "--"} </p>
                        <Link className='nav_link' to='/profile'>My Profile</Link>
                    </div>

                    <div className="box_2">
                        <h3 className="time__">
                            {date}
                        </h3>
                        <h1 className="time__">
                            {time}
                        </h1>
                    </div>

                    <nav className="box_3">
                        {
                            navLinks.map((link, index) => (
                                <Tooltip title={link.description} key={link.path} align='right' placement='right'>
                                    <Link className='nav_link' to={link.path}> {<link.icon />} {link.label}</Link>
                                </Tooltip>
                            ))
                        }
                    </nav>



                    <div className="box_5">
                        <Tooltip title='Logout' align='right' placement='right'>
                            <Button
                                icon={<PoweroffOutlined />}
                                type='text'
                                className='logout_btn'
                                onClick={handleLogout}
                            >
                                Logout
                            </Button>
                        </Tooltip>
                    </div>

                </div>
            </div>

            <EditAvatar />
            <FloatButton
                onClick={toggleSidebar}
                icon={<AppstoreOutlined style={{ color: "var(--bgRed)", width: "180%" }} />}
                style={{ color: "var(--bgRed)", }}

            />

            <div className="main_wrapper">
                <div className="fixed_bar">
                    <div className="row_1">
                        {/* <span> {`Home / appointments / 25`} </span> */}
                        <Breadcrumbs />
                        <div className="action_btn_grp">
                            <Button icon={<BellFilled />} type='text' />
                            <Button icon={<MessageFilled />} type='text' />
                        </div>
                    </div>
                </div>

                <div className="movable_area">

                    <Routes>
                        <Route path='/' element={<Dashboard appState={appState} />} />
                        <Route path='/appointments' element={<Appointments appState={appState} />} />
                        <Route path='/appointments/:id' element={<ViewAppoinment appState={appState} />} />
                        <Route path='/patients' element={<Patients appState={appState} />} />
                        <Route path='/chats' element={<Chats appState={appState} />} />
                        {/* <Route path='/consultation-session/:aptId/:docId/:patId' element={<ConsultationSession />} /> */}

                        {/* <Route path='/profile' element={<Profile appState={appState} />} /> */}
                        <Route path='/profile' element={<EditProfile appState={appState} />} />
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default DoctorsDashboard
