import { Button, Form, Input } from 'antd'
import React, { useContext, useEffect } from 'react'
import './Login.css'
import { SharedContext } from '../../Context/SharedContextProvider/SharedContextProvider'
import { Link } from 'react-router-dom'
import { LoginContext } from '../../Context/LoginContextProvider/LoginContextProvider'

const Login = () => {
    const { logo } = useContext(SharedContext)
    const { setUserData, handleLogin } = useContext(LoginContext)

    useEffect(() => {
        document.title = `Login | Neomerit Metro Health`

        return () => {
            document.title = `Dashboard | Neomerit Metro Health`
        }
    }, [])

    return (
        <div className='login_wrapper'>
            <div className="inner__">
                <div className="banner_container__">
                    <div className="image_box">
                        <img src={logo} alt="" />
                    </div>
                    <h1 className='title__'>   Neomerit Metro Health </h1>
                </div>

                <div className="form_container__">
                    <Form layout='vertical' name='loginForm'>
                        <div className="form_grp__">
                            <div className="title__">Login</div>
                            <Form.Item label='Email'>
                                <Input placeholder='example@mail.com' name='email'
                                    onChange={e => setUserData(prev => ({ ...prev, email: e.target.value }))}
                                />
                            </Form.Item>
                            <Form.Item label='Password'>
                                <Input.Password placeholder='Password' name='password'
                                    onChange={e => setUserData(prev => ({ ...prev, password: e.target.value }))}
                                />
                            </Form.Item>
                            <Form.Item label=''>
                                <Button onClick={handleLogin} >Login</Button>
                            </Form.Item>
                            <p className='sub_text'> <Link to='/forgot-password'>Forgot Password</Link> ?</p>
                            <p className='sub_text'>Dont have an account? <Link to='/signup?step=1'>Signup as Doctor/Nurse</Link> </p>
                        </div>
                    </Form>
                </div>

            </div>

        </div>
    )
}

export default Login
