import React from 'react'

const NotFound = () => {
    return (
        <div
            style={{ display: "grid", placeItems: "center", placeContent: "center" }}
        >
            <h1>Not Found ☹️ </h1>
        </div>
    )
}

export default NotFound
