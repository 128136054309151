import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
// import { AnimatePresence } from "framer-motion";
import DoctorsDashboard from "./Components/DoctorsDashboard/DoctorsDashboard";
import NotFound from "./Components/NotFound/NotFound";
import ConsultationSession from "./Components/DoctorsDashboard/Appointments/ConsultationSession/ConsultationSession";
import Signup from "./Components/Signup/Signup";
import Login from "./Components/Login/Login";
import { useContext } from "react";
import { AppContext } from "./Context/AppContext/AppContextProvider";
import Loading from "./Components/Loading/Loading";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";

function App() {
  const location = useLocation();

  const { appState } = useContext(AppContext);

  return (
    <div className="App">
      {/* <AnimatePresence mode="wait"> */}
      <Routes location={location} key={location.pathname}>
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <DoctorsDashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />

        <Route
          path="/consultation-session/:aptId/:docId/:patId"
          element={<ConsultationSession />}
        />

        {/* <Route path="/profile/:id" element={<h1>Public profile</h1>} /> */}

        <Route path="*" element={<NotFound />} />
      </Routes>
      {appState.isLoading && <Loading />}
      {/* </AnimatePresence> */}
    </div>
  );
}

export default App;
