import React from 'react'

const ConsultationSession = () => {
    return (
        <div>
            ConsultationSession
            ConsultationSession
        </div>
    )
}

export default ConsultationSession
