import {  notification } from "antd";
// import { clearCredentialsAndRedirectToLoginPage } from "./clearCredentialsAndRedirectToLoginPage";

notification.config({
  placement: "bottomRight",
});

export function handleHttpSuccessMessage(response) {
  // if (response.status === 200 || response.status === 201) {
  notification.success({
    message: response.data.message,
  });
  // }
}
