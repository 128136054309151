export const specializations = [
  {
    label: "Allergy and Immunology",
    value: "Allergy and Immunology",
  },
  {
    label: "Anesthesiology",
    value: "Anesthesiology",
  },
  {
    label: "Bariatrics",
    value: "Bariatrics",
  },
  {
    label: "Cardiology",
    value: "Cardiology",
  },
  {
    label: "Dermatology",
    value: "Dermatology",
  },
  {
    label: "Emergency Medicine",
    value: "Emergency Medicine",
  },
  {
    label: "Endocrinology",
    value: "Endocrinology",
  },
  {
    label: "Family Medicine",
    value: "Family Medicine",
  },
  {
    label: "Gastroenterology",
    value: "Gastroenterology",
  },
  {
    label: "Geriatrics",
    value: "Geriatrics",
  },
  {
    label: "Hematology",
    value: "Hematology",
  },
  {
    label: "Infectious Disease",
    value: "Infectious Disease",
  },
  {
    label: "Internal Medicine",
    value: "Internal Medicine",
  },
  {
    label: "Nephrology",
    value: "Nephrology",
  },
  {
    label: "Neurology",
    value: "Neurology",
  },
  {
    label: "Obstetrics and Gynecology",
    value: "Obstetrics and Gynecology",
  },
  {
    label: "Oncology",
    value: "Oncology",
  },
  {
    label: "Ophthalmology",
    value: "Ophthalmology",
  },
  {
    label: "Orthopedics",
    value: "Orthopedics",
  },
  {
    label: "Otolaryngology",
    value: "Otolaryngology",
  },
  {
    label: "Pediatrics",
    value: "Pediatrics",
  },
  {
    label: "Physical Medicine and Rehabilitation",
    value: "Physical Medicine and Rehabilitation",
  },
  {
    label: "Psychiatry",
    value: "Psychiatry",
  },
  {
    label: "Pulmonology",
    value: "Pulmonology",
  },
  {
    label: "Radiology",
    value: "Radiology",
  },
  {
    label: "Rheumatology",
    value: "Rheumatology",
  },
  {
    label: "Surgery",
    value: "Surgery",
  },
];
