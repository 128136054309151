import React, { useContext, useEffect, useState } from 'react'
import './EditProfile.css'
import { SaveOutlined } from '@ant-design/icons'
import { motion } from "framer-motion"
import { Button, } from 'antd'
import EditAvatar from '../EditAvatar/EditAvatar'
// import { SharedContext } from '../../../../Context/SharedContextProvider/SharedContextProvider'
// import { AppContext } from '../../../../Context/AppContext/AppContextProvider'
import { CustomFunctionsContext } from '../../../../Context/CustomFunctionsContextProvider/CustomFunctionsContextProvider'
import { SettingsContext } from '../../../../Context/SettingsContextProvider/SettingsContextProvider'
import { ProfileContext } from '../../../../Context/ProfileContextProvider/ProfileContextProvider'
// import { ProfileContext } from '../../../../Context/ProfileContextProvider/ProfileContextProvider'

const EditProfile = () => {
    // const {
    //     // navigate,
    //     // testImage,
    //     // intlFormatDistance,
    //     // specializations,
    //     // formatHumanReadableDate
    // } = useContext(SharedContext)

    const {
        getSpecializations,
        getDoctorProfile
    } = useContext(SettingsContext)

    const {
        // showModal_EditAvatar,
        // 
        myProfile,
        setMyProfile,
        // 
        handleSubmitFormData_A,
        handleSubmitFormData_B,
        handleSubmitFormData_C,
        handleSubmitFormData_D,
        handleSubmitFormData_E,
        handleSubmitFormData_F,
        // 
    } = useContext(ProfileContext)


    // const { appState, } = useContext(AppContext)
    // const { formatHumanReadableDate } = useContext(SharedContext)
    const { handleHTTPErrors, } = useContext(CustomFunctionsContext);

    // --------------------------------------------------------
    const [specializationList, setSpecializationList] = useState([])

    const [isAtTop, setIsAtTop] = useState(true);

    //    ==============================================
    useEffect(() => {
        async function fxn() {
            try {
                // showGlobalLoading();
                const response = await getDoctorProfile();
                // console.log(response)

                if (response.status === 200) {
                    setMyProfile(response?.data?.data)
                    console.log(response?.data?.data)
                }
            } catch (error) {
                console.log(error)
                handleHTTPErrors(error);
            }
        }

        fxn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const handleScroll = () => {
        if (window.scrollY === 0) {
            setIsAtTop(true);
        } else {
            setIsAtTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        document.title = `Profile | Neomerit Metro Health`

        return () => {
            document.title = `Dashboard | Neomerit Metro Health`
        }
    }, [])

    useEffect(() => {
        async function fxn() {
            try {
                const response = await getSpecializations();
                if (response.status === 200) {
                    setSpecializationList(response.data.data)
                }
            } catch (error) {
                console.log(error)
                handleHTTPErrors(error);
            }
        }
        fxn()

        // eslint-disable-next-line
    }, [])


    // ----------------------------------
    const [formData_A, setFormData_A] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        prefix: '',
        gender: '',
        phone1: '',
        phone2: '',
    });

    useEffect(() => {
        setFormData_A({
            firstName: myProfile?.first_name || '',
            middleName: myProfile?.middle_name || '',
            lastName: myProfile?.last_name || '',
            prefix: myProfile?.prefix || '',
            gender: myProfile?.gender || '',
            phone1: myProfile?.phone_1 || '',
            phone2: myProfile?.phone_2 || '',
        });
    }, [myProfile]);

    const handleChange_A = (e) => {
        const { name, value } = e.target;
        setFormData_A(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    // -------------------------------------

    const [formData_B, setFormData_B] = useState({
        bio: '',
        specializationId: '',
        yearsOfExperience: '',
        licenseNumber: '',
        address: ''
    });

    useEffect(() => {
        setFormData_B({
            bio: myProfile?.bio || '',
            specializationId: myProfile?.specialization_id || '',
            yearsOfExperience: myProfile?.years_of_experience || '',
            licenseNumber: myProfile?.license_number || '',
            address: myProfile?.address || ''
        });
    }, [myProfile]);

    const handleChange_B = (e) => {
        const { name, value } = e.target;
        setFormData_B(prevState => ({
            ...prevState,
            [name]: value
        }));
        // console.log(name, value)
    };
    // -------------------------------------

    const [formData_C, setFormData_C] = useState({
        q1: '',
        q2: '',
        q3: '',
        q4: '',
    });

    useEffect(() => {
        setFormData_C({
            q1: myProfile?.q1 || '',
            q2: myProfile?.q2 || '',
            q3: myProfile?.q3 || '',
            q4: myProfile?.q4 || '',
        });
    }, [myProfile]);

    const handleChange_C = (e) => {
        const { name, value } = e.target;
        setFormData_C(prevState => ({
            ...prevState,
            [name]: value
        }));
        // console.log(name, value)
    };
    // -------------------------------------

    const [formData_D, setFormData_D] = useState({
        affiliation1: '',
        affiliation2: '',
    });

    useEffect(() => {
        setFormData_D({
            affiliation1: myProfile?.affiliation1 || '',
            affiliation2: myProfile?.affiliation2 || '',
        });
    }, [myProfile]);

    const handleChange_D = (e) => {
        const { name, value } = e.target;
        setFormData_D(prevState => ({
            ...prevState,
            [name]: value
        }));
        // console.log(name, value)
    };
    // -------------------------------------

    const [formData_E, setFormData_E] = useState({
        mon_from: '',
        mon_to: "",

        tue_from: "",
        tue_to: "",

        wed_from: "",
        wed_to: "",

        thu_from: "",
        thu_to: "",

        fri_from: "",
        fri_to: "",

        sat_from: "",
        sat_to: "",

        sun_from: "",
        sun_to: "",
    });

    useEffect(() => {
        setFormData_E({
            mon_from: myProfile?.mon_from || '',
            mon_to: myProfile?.mon_to || '',

            tue_from: myProfile?.tue_from || '',
            tue_to: myProfile?.tue_to || '',

            wed_from: myProfile?.wed_from || '',
            wed_to: myProfile?.wed_to || '',

            thu_from: myProfile?.thu_from || '',
            thu_to: myProfile?.thu_to || '',

            fri_from: myProfile?.fri_from || '',
            fri_to: myProfile?.fri_to || '',

            sat_from: myProfile?.sat_from || '',
            sat_to: myProfile?.sat_to || '',

            sun_from: myProfile?.sun_from || '',
            sun_to: myProfile?.sun_to || '',
        });
    }, [myProfile]);

    const handleChange_E = (e) => {
        const { name, value } = e.target;
        setFormData_E(prevState => ({
            ...prevState,
            [name]: value
        }));
        // console.log(name, value)
    };
    // -----------------------------------

    const [formData_F, setFormData_F] = useState({
        lang1: '',
        lang2: '',
    });

    useEffect(() => {
        setFormData_F({
            lang1: myProfile?.lang1 || '',
            lang2: myProfile?.lang2 || '',
        });
    }, [myProfile]);

    const handleChange_F = (e) => {
        const { name, value } = e.target;
        setFormData_F(prevState => ({
            ...prevState,
            [name]: value
        }));
        // console.log(name, value)
    };
    // -------------------------------------

    return (
        <div
            className='general_frame_wrapper'
        >
            <div className={`${!isAtTop ? "heading_bar" : "heading_bar_2"}`}>

                {/* <Button icon={<ArrowLeftOutlined />} type='text' size='large' onClick={() => navigate('/profile')} /> */}
                <motion.h1
                    style={{ display: "flex", alignItems: "center", gap: "1rem" }}

                    initial={{ opacity: 0, translateY: "-10px", }}
                    animate={{ opacity: 1, translateY: "0", }}
                    transition={{ duration: 0.5 }}
                >
                    <span>
                        {/* <EditOutlined /> */}
                        Profile
                    </span>
                </motion.h1>
            </div >

            <motion.div
                className='profile_wrapper'

                initial={{ opacity: 0, translateX: "15px", width: "80%" }}
                animate={{ opacity: 1, translateX: "0", width: "100%" }}
                transition={{ duration: 0.5 }}
            >
                <div className="profile_wrapper_inner__">

                    <div className="profile_wrapper_inner__right">
                        <div className="profile_wrapper_inner__right__top"></div>

                        <div className="profile_wrapper_inner__right__bottom EditProfile_wrapper__">
                            {/* <div className="section__title">Personal  </div> */}
                            <div className="section__">
                                <div className="section__title">Personal  </div>
                                <div className="section__content">
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">First Name</div>
                                        <div className="section__content__item__value">
                                            <input
                                                type="text"
                                                name="firstName"
                                                value={formData_A?.firstName}
                                                onChange={handleChange_A}
                                                placeholder="First name"
                                                className='input_field'
                                            />

                                        </div>
                                    </div>
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Middle Name</div>
                                        <div className="section__content__item__value">
                                            <input
                                                type="text"
                                                name="middleName"
                                                value={formData_A.middleName}
                                                onChange={handleChange_A}
                                                placeholder="Middle name"
                                                className='input_field'
                                            />
                                        </div>
                                    </div>
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Last Name</div>
                                        <div className="section__content__item__value">
                                            <input
                                                type="text"
                                                name="lastName"
                                                value={formData_A.lastName}
                                                onChange={handleChange_A}
                                                placeholder="Last name"
                                                className='input_field'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="section__content" >
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Prefix</div>
                                        <div className="section__content__item__value">
                                            <select
                                                name="prefix"
                                                id="prefix"
                                                className='input_field'
                                                value={formData_A.prefix}
                                                onChange={handleChange_A}

                                            // value={selectedPrefix}
                                            // onChange={e => setSelectedPrefix(e.target.value)}
                                            >
                                                <option value="">Choose Prefix</option>
                                                <option value="Dr.">Dr.</option>
                                                <option value="Rn.">Rn.</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Gender</div>
                                        <div className="section__content__item__value">
                                            <select
                                                name="gender"
                                                id="gender"
                                                className='input_field'
                                                value={formData_A.gender}
                                                onChange={handleChange_A}

                                            // value={selectedGender}
                                            // onChange={e => setSelectedGender(e.target.value)}
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <br />
                                <div className="section__content">
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Phone 1</div>
                                        <div className="section__content__item__value">
                                            <input
                                                type="text"
                                                name="phone1"
                                                value={formData_A.phone1}
                                                onChange={handleChange_A}
                                                placeholder="Phone 1"
                                                className='input_field'
                                            />
                                        </div>
                                    </div>
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Phone 2</div>
                                        <div className="section__content__item__value">
                                            <input
                                                type="text"
                                                name="phone2"
                                                value={formData_A.phone2}
                                                onChange={handleChange_A}
                                                placeholder="Phone 2"
                                                className='input_field'
                                            />
                                        </div>
                                    </div>
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Email</div>
                                        <div className="section__content__item__value">
                                            <input
                                                type="email"
                                                name="email"
                                                value={myProfile?.email}
                                                placeholder="email"
                                                className='input_field'
                                                disabled
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="save_changes_box">
                                    <Button
                                        icon={<SaveOutlined />}
                                        type='text'
                                        size='large'
                                        className='edit_profile_btn'
                                        onClick={() => handleSubmitFormData_A(formData_A)}
                                    >
                                        Save Changes
                                    </Button>
                                </div>

                            </div>


                            <div className="section__">
                                <div className="section__title">Bio   </div>
                                <div className="section__content">
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Bio</div>
                                        <div className="section__content__item__value">
                                            <textarea
                                                name="bio"
                                                id=""
                                                className='input_field'
                                                placeholder="Bio"

                                                value={formData_B.bio}
                                                onChange={handleChange_B}

                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <div className="section__title">Specialization   </div>
                                <div className="section__content">
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">The medical field in which you specialize in</div>
                                        <div className="section__content__item__value">

                                            <select
                                                name='specializationId'
                                                id="specialization"
                                                className='input_field'

                                                value={formData_B.specializationId}
                                                onChange={handleChange_B}
                                            >
                                                {
                                                    specializationList.length > 0
                                                        ? specializationList.map((item, index) => (
                                                            <option value={item.id} key={index}>
                                                                {item.type}
                                                            </option>
                                                        ))
                                                        : <option value="">--</option>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <div className="section__title">Experience </div>
                                <div className="section__content">
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">The number of years you have been practicing.</div>
                                        <div className="section__content__item__value">
                                            <input
                                                type="text"
                                                name='yearsOfExperience'
                                                placeholder="Years of expereince"
                                                className='input_field'
                                                min={0}

                                                value={formData_B.yearsOfExperience}
                                                onChange={handleChange_B}
                                            />

                                        </div>
                                    </div>

                                </div>

                                <br />
                                <div className="section__title">License Number  </div>
                                <div className="section__content">
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Your medical license number</div>
                                        <div className="section__content__item__value">
                                            <input
                                                type="text"
                                                name='licenseNumber'
                                                placeholder="License Number"
                                                className='input_field'

                                                value={formData_B?.licenseNumber}
                                                onChange={handleChange_B}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <br />
                                <div className="section__title">Address  </div>
                                <div className="section__content">

                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Address</div>
                                        <div className="section__content__item__value">
                                            <textarea
                                                name="address"
                                                id=""
                                                className='input_field'
                                                placeholder="Address"

                                                // 
                                                value={formData_B.address}
                                                onChange={handleChange_B}
                                            ></textarea>

                                        </div>
                                    </div>
                                </div>

                                <div className="save_changes_box">
                                    <Button
                                        icon={<SaveOutlined />}
                                        type='text'
                                        size='large'
                                        className='edit_profile_btn'
                                        onClick={() => handleSubmitFormData_B(formData_B)}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </div>

                            <div className="section__">
                                <div className="section__title">Qualifications </div>
                                <div className="section__content">
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Educational background and degrees 1</div>
                                        <div className="section__content__item__value">
                                            <div className="section__content__item__value__item">

                                                <input
                                                    type="text"
                                                    name='q1'
                                                    placeholder="Qualification 1"
                                                    className='input_field'

                                                    value={formData_C.q1}
                                                    onChange={handleChange_C}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Educational background and degrees 2</div>
                                        <div className="section__content__item__value">
                                            <div className="section__content__item__value__item">
                                                <input
                                                    type="text"
                                                    name='q2'
                                                    placeholder="Qualification 2"
                                                    className='input_field'


                                                    value={formData_C.q2}
                                                    onChange={handleChange_C}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Educational background and degrees 3</div>
                                        <div className="section__content__item__value">
                                            <div className="section__content__item__value__item">
                                                <input
                                                    type="text"
                                                    name='q3'
                                                    placeholder="Qualification 3"
                                                    className='input_field'

                                                    value={formData_C.q3}
                                                    onChange={handleChange_C}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Educational background and degrees 4</div>
                                        <div className="section__content__item__value">
                                            <div className="section__content__item__value__item">
                                                <input
                                                    type="text"
                                                    name='q4'
                                                    placeholder="Qualification 4"
                                                    className='input_field'

                                                    value={formData_C.q4}
                                                    onChange={handleChange_C}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="save_changes_box">
                                    <Button
                                        icon={<SaveOutlined />}
                                        type='text'
                                        size='large'
                                        className='edit_profile_btn'
                                        onClick={() => handleSubmitFormData_C(formData_C)}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </div>



                            <div className="section__">
                                <div className="section__title">Affiliations </div>
                                <div className="section__content">
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Hospitals or medical institutions you are affiliated with.</div>
                                        <div className="section__content__item__value">

                                            <input
                                                type="text"
                                                name='affiliation1'
                                                placeholder="Affiliation 1"
                                                className='input_field'

                                                value={formData_D.affiliation1}
                                                onChange={handleChange_D}
                                            />
                                        </div>
                                    </div>
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Hospitals or medical institutions you are affiliated with.</div>
                                        <div className="section__content__item__value">
                                            <input
                                                type="text"
                                                name='affiliation2'
                                                placeholder="Affiliation 2"
                                                className='input_field'

                                                value={formData_D.affiliation2}
                                                onChange={handleChange_D}

                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="save_changes_box">
                                    <Button
                                        icon={<SaveOutlined />}
                                        type='text'
                                        size='large'
                                        className='edit_profile_btn'
                                        onClick={() => handleSubmitFormData_D(formData_D)}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </div>

                            <div className="section__">
                                <div className="section__title">Availability  </div>
                                <div className="section__content">
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">Your available hours for appointments</div>
                                        <div className="section__content__item__value">
                                            <table className='availability_table'>
                                                <tbody>
                                                    <tr>
                                                        <td className='days'>Monday</td>
                                                        <td className='time' style={{ display: "flex", alignItems: "center" }}>
                                                            <label htmlFor="mon_from" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                From:
                                                                <input type="time" name="mon_from" id="mon_from" value={formData_E.mon_from} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                            &nbsp;
                                                            &nbsp;

                                                            <label htmlFor="mon_to" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                To:
                                                                <input type="time" name="mon_to" id="mon_to" value={formData_E.mon_to} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='days'>Tuesday</td>
                                                        <td className='time' style={{ display: "flex", alignItems: "center" }}>
                                                            <label htmlFor="tue_from" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                From:
                                                                <input type="time" name="tue_from" id="tue_from" value={formData_E.tue_from} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                            &nbsp;
                                                            &nbsp;

                                                            <label htmlFor="tue_to" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                To:
                                                                <input type="time" name="tue_to" id="tue_to" value={formData_E.tue_to} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='days'>Wednesday</td>
                                                        <td className='time' style={{ display: "flex", alignItems: "center" }}>
                                                            <label htmlFor="wed_from" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                From:
                                                                <input type="time" name="wed_from" id="wed_from" value={formData_E.wed_from} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                            &nbsp;
                                                            &nbsp;

                                                            <label htmlFor="wed_to" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                To:
                                                                <input type="time" name="wed_to" id="wed_to" value={formData_E.wed_to} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='days'>Thursday</td>
                                                        <td className='time' style={{ display: "flex", alignItems: "center" }}>
                                                            <label htmlFor="thu_from" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                From:
                                                                <input type="time" name="thu_from" id="thu_from" value={formData_E.thu_from} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                            &nbsp;
                                                            &nbsp;

                                                            <label htmlFor="thu_to" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                To:
                                                                <input type="time" name="thu_to" id="thu_to" value={formData_E.thu_to} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='days'>Friday</td>
                                                        <td className='time' style={{ display: "flex", alignItems: "center" }}>
                                                            <label htmlFor="fri_from" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                From:
                                                                <input type="time" name="fri_from" id="fri_from" value={formData_E.fri_from} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                            &nbsp;
                                                            &nbsp;

                                                            <label htmlFor="fri_to" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                To:
                                                                <input type="time" name="fri_to" id="fri_to" value={formData_E.fri_to} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='days'>Saturday</td>
                                                        <td className='time' style={{ display: "flex", alignItems: "center" }}>
                                                            <label htmlFor="sat_from" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                From:
                                                                <input type="time" name="sat_from" id="sat_from" value={formData_E.sat_from} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                            &nbsp;
                                                            &nbsp;

                                                            <label htmlFor="sat_to" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                To:
                                                                <input type="time" name="sat_to" id="sat_to" value={formData_E.sat_to} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='days'>Sunday</td>
                                                        <td className='time' style={{ display: "flex", alignItems: "center" }}>
                                                            <label htmlFor="sun_from" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                From:
                                                                <input type="time" name="sun_from" id="sun_from" value={formData_E.sun_from} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                            &nbsp;
                                                            &nbsp;

                                                            <label htmlFor="sun_to" style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                                                                To:
                                                                <input type="time" name="sun_to" id="sun_to" value={formData_E.sun_to} onChange={handleChange_E} className='input_field' />
                                                            </label>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="save_changes_box">
                                    <Button
                                        icon={<SaveOutlined />}
                                        type='text'
                                        size='large'
                                        className='edit_profile_btn'
                                        onClick={() => handleSubmitFormData_E(formData_E)}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </div>

                            <div className="section__">
                                <div className="section__title">Languages Spoken </div>
                                <div className="section__content">
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">The languages you are fluent in 1</div>
                                        <div className="section__content__item__value">
                                            <input
                                                type="text"
                                                name='lang1'
                                                placeholder="Lang 1"
                                                className='input_field'

                                                value={formData_F.lang1}
                                                onChange={handleChange_F}
                                            />
                                        </div>
                                    </div>
                                    <div className="section__content__item">
                                        <div className="section__content__item__label">The languages you are fluent in 2</div>
                                        <div className="section__content__item__value">
                                            <input
                                                type="text"
                                                name='lang2'
                                                placeholder="Lang 2"
                                                className='input_field'

                                                value={formData_F.lang2}
                                                onChange={handleChange_F}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="save_changes_box">
                                    <Button
                                        icon={<SaveOutlined />}
                                        type='text'
                                        size='large'
                                        className='edit_profile_btn'
                                        onClick={() => handleSubmitFormData_F(formData_F)}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </motion.div >


            <EditAvatar />

        </div >
    )
}

export default EditProfile
