import React, { createContext, useContext, useState } from 'react'
import { SharedContext } from '../SharedContextProvider/SharedContextProvider';
import { AppContext } from '../AppContext/AppContextProvider';
import { SettingsContext } from '../SettingsContextProvider/SettingsContextProvider';
import { CustomFunctionsContext } from '../CustomFunctionsContextProvider/CustomFunctionsContextProvider';
import { message } from 'antd';

export const LoginContext = createContext()

const LoginContextProvider = ({ children }) => {
    const {
        // navigate,
        notification
    } = useContext(SharedContext)

    const { showGlobalLoading, hideGlobalLoading, } = useContext(AppContext);
    const { login, logout } = useContext(SettingsContext);
    const { handleHTTPErrors, clearCredentialsAndRedirectToLoginPage } = useContext(CustomFunctionsContext);

    const [userData, setUserData] = useState({
        email: null,
        password: null
    })

    async function handleLogin() {
        if (Object.values(userData).includes(null)) {
            notification.error({
                message: "All fields are required!",
            })
            return false
        }

        try {
            showGlobalLoading();
            const response = await login(userData);

            // console.log(response)

            if (response.status === 200) {
                message.success(response.data.message);
                // console.log(response)

                // notify success
                notification.success({
                    message: "Login Successfully",
                    description: "User Login successful",
                })

                // console.log(response.data.data)

                localStorage.setItem('NMH_USER_DATA', JSON.stringify(response.data.data))
                localStorage.setItem('NMH_LOGIN_TOKEN', response.data.token)

                window.location = `/`
                // navigate(`/`)
            }
            hideGlobalLoading();
        } catch (error) {
            // console.log(error)
            hideGlobalLoading();
            handleHTTPErrors(error);
        }

    }

    async function handleLogout() {

        try {
            showGlobalLoading();
            const response = await logout();

            // console.log(response)

            if (response.status === 200) {
                console.log(response)
                message.success(response.data.message);

                // notify success
                notification.success({
                    message: "Logout Successful",
                })
                clearCredentialsAndRedirectToLoginPage()
            }
            hideGlobalLoading();
        } catch (error) {
            // console.log(error)
            hideGlobalLoading();
            handleHTTPErrors(error);
        }

    }

    const values = {
        userData, setUserData,
        // 
        handleLogin,
        handleLogout
    }
    return (
        <LoginContext.Provider value={values}>
            {children}
        </LoginContext.Provider>
    )
}

export default LoginContextProvider
