export function setCredentialsAndRedirectToDashboardPage(response) {
  // save user details into localstorage
  localStorage.setItem(
    "ELECTIONSPOTTER_USER_DATA",
    JSON.stringify(response?.data?.data)
  );
  // set isAuth to true
  localStorage.setItem("ELECTIONSPOTTER_IS_AUTH", true);

  // save login token in localstorage
  localStorage.setItem(
    "ELECTIONSPOTTER_LOGIN_TOKEN",
    response?.data?.meta?.token
  );

  localStorage.setItem(
    "ELECTIONSPOTTER_LOGIN_PERMISSIONS",
    response?.data?.meta?.permissions
  );

  // -------------------------------------
  window.location = "/";
}
