import React, { useContext, } from 'react'
import '../SignUp.css'
// import { UserOutlined, MailOutlined, DashboardFilled } from '@ant-design/icons'
import { Button, Form, Input, } from 'antd'
import { motion } from "framer-motion"
// import { SharedContext } from "../../../Context/SharedContextProvider/SharedContextProvider";
import { SignupContext } from "../../../Context/SignupContextProvider/SignupContextProvider";
// const { Option } = Select;

const Otp = () => {

    // const { navigate } = useContext(SharedContext)

    const {
        // 
        // currentStep,
        // setCurrentStep,
        // // 
        // userType,
        // setUserType,
        // // 
        // message,
        // setMessage,
        // // 
        // loading,
        // setLoading,
        // // 
        // handleSignup,
        handleVerifyEmail,
        // handleNavigateToDashboard,
    } = useContext(SignupContext)

    return (
        <motion.div
            className="otp_wrapper"

            initial={{ opacity: 0, scale: 0.5, }}

            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            <h1 className="row_1">
                Verify Your Email
            </h1>
            <p className="subtext">
                Please enter 4-digit code sent to <span style={{ textTransform: "lowercase", fontWeight: "500" }}>
                    {/* {createdUser.email} */}
                </span>
            </p>

            <Form
                name='otp'
                layout="vertical"
                onFinish={handleVerifyEmail}
            >
                <div className="form_grp">
                    <Form.Item
                        // label="Success"
                        hasFeedback
                        validateStatus="success"
                        required
                        name='otp_value'
                    >
                        <Input.OTP size='large' autoFocus length={4} />
                    </Form.Item>

                    <div className="btn_grp">
                        {/* <Button type="primary" size='large' className='btnType6' onClick={() => handleGoBack(1)}>Previous</Button> */}
                        <Button htmlType='submit' type="primary" size='large' className='btnType3' >Continue</Button>
                    </div>
                </div>
            </Form>


        </motion.div>
    )
}

export default Otp
