import React, { createContext, useState } from 'react'

export const AppointmentsContext = createContext()


const AppointmentsContextProvider = ({ children }) => {

    const [appointments, setAppointments] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [filteredAppointments, setFilteredAppointments] = useState([...appointments])
    const [dateSorted, setDateSorted] = useState(false)



    // Functions to handle the filter of appointments by date and name.     
    function searchByDate(date) {
        let filtered = appointments.filter((item) => item.appointmentDate === date);
        setFilteredAppointments(filtered)
    }

    function searchByPatient(name) {
        let lowercasedName = name.toLowerCase()
        let filtered = appointments.filter((item) => item.patientName.toLowerCase()
            .includes(lowercasedName))
        setFilteredAppointments(filtered)
    }

    function resetFilter() {
        setFilteredAppointments(appointments)
        setSearchValue('')
    }

    // Function to sort appointments by appointmentDate
    function sortByDate() {
        let sorted = filteredAppointments.sort((a, b) => (a.appointmentDate - b.appointmentDate) ? 1 : -1)
        // let sorted = filteredAppointments.sort((a, b) => (a.appointmentDate > b.appointmentDate))
        setFilteredAppointments(sorted)
        setDateSorted(!dateSorted)
    }

    const values = {
        searchValue,
        setSearchValue,

        appointments,
        setAppointments,

        filteredAppointments,
        searchByDate,
        searchByPatient,
        sortByDate,
        dateSorted,
        resetFilter,


    }
    return (
        <AppointmentsContext.Provider value={values}>
            {children}
        </AppointmentsContext.Provider>
    )
}

export default AppointmentsContextProvider
