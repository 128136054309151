import React, { createContext,  } from 'react'

export const EditProfileContext = createContext()

const EditProfileContextProvider = ({ children }) => {

    // const [editData, setEditData] = useState({
    //     prefix: null,
    //     first_name: null,
    //     middle_name: null,
    //     last_name: null,
    //     gender: null,
    //     specialization: null,
    //     bio: null,
    //     // specialization: null,
    //     years_of_experience: null,
    //     license_number: null,
    //     address: null,
    // })

    const values = {

    }
    return (
        <EditProfileContext.Provider value={values}>
            {children}
        </EditProfileContext.Provider>
    )
}

export default EditProfileContextProvider
