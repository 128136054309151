import { useCallback, useContext, useEffect } from "react";
import { message } from "antd";
import { AppContext } from "../AppContext/AppContextProvider";
// import { AuthContext } from "../AuthContextProvider/AuthContextProvider";
import { CustomFunctionsContext } from "../CustomFunctionsContextProvider/CustomFunctionsContextProvider";

// function useGetRequest(getRequestFunction, setDataFunction, args) {
//   const { showGlobalLoading, hideGlobalLoading } = useContext(AppContext);
//   const { handleHTTPErrors } = useContext(CustomFunctionsContext);

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         showGlobalLoading();
//         const response = await getRequestFunction(args ? args : null);

//         if (response.status === 200) {
//           message.success(response.data.message);
//           setDataFunction(response.data.data);
//         }
//         hideGlobalLoading();
//       } catch (error) {
//         hideGlobalLoading();
//         handleHTTPErrors(error);
//       }
//     }
//     fetchData();
//   }, [getRequestFunction, setDataFunction]);
// }

// export default useGetRequest;

function useGetRequest(getRequestFunction, setDataFunction, args) {
  const { showGlobalLoading, hideGlobalLoading } = useContext(AppContext);
  const { handleHTTPErrors } = useContext(CustomFunctionsContext);

  const fetchData = useCallback(async () => {
    try {
      showGlobalLoading();
      const response = await getRequestFunction(args ? args : null);

      if (response.status === 200) {
        message.success(response.data.message);
        setDataFunction(response.data.data);
      }
      hideGlobalLoading();
    } catch (error) {
      hideGlobalLoading();
      handleHTTPErrors(error);
    }
  }, [
    getRequestFunction,
    setDataFunction,
    args,
    showGlobalLoading,
    hideGlobalLoading,
    handleHTTPErrors,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
}

export default useGetRequest;
