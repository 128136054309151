import React, { createContext } from 'react'
import { clearCredentialsAndRedirectToLoginPage } from './clearCredentialsAndRedirectToLoginPage'
import { setCredentialsAndRedirectToDashboardPage } from './setCredentialsAndRedirectToDashboardPage'
import { handleHTTPErrors } from './handleHTTPErrors'
import { mapSelectOptions } from './mapSelectOptions'
import getRequest from './getRequest'
import { mapSelectOptionsForState } from './mapSelectOptionsForState'
import { mapSelectOptionForProject } from './mapSelectOptionForProject'
import { handleHttpSuccessMessage } from './handleHttpSuccessMessage'
import { convertDateOfBirth } from './convertDateOfBirth'
import { transformZoneList } from './transformZoneList'
import { transformStateList } from './transformStateList'
import { transformLgaList } from './transformLgaList'
import { transformRaList } from './transformRaList'
import { transformPuList } from './transformPuList'
import { formatDate } from './dateFunctions/formatDate'
import { formatTime } from './dateFunctions/formatTime'
import { transformSpecialization } from './transformSpecialization'

export const CustomFunctionsContext = createContext()

const CustomFunctionsContextProvider = ({ children }) => {

    const values = {
        setCredentialsAndRedirectToDashboardPage,
        clearCredentialsAndRedirectToLoginPage,
        handleHTTPErrors,
        getRequest,
        mapSelectOptions,
        mapSelectOptionsForState,
        mapSelectOptionForProject,
        handleHttpSuccessMessage,
        convertDateOfBirth,
        // 
        transformZoneList,
        transformStateList,
        transformLgaList,
        transformRaList,
        transformPuList,
        transformSpecialization,
        // 
        formatDate,
        formatTime
    }
    return (
        <CustomFunctionsContext.Provider value={values}>
            {children}
        </CustomFunctionsContext.Provider>
    )
}

export default CustomFunctionsContextProvider
