import React, { useContext, useEffect, useState } from 'react'
import { DeleteOutlined, PlusOutlined, SortAscendingOutlined, ArrowLeftOutlined, DownloadOutlined, EyeOutlined, SortDescendingOutlined, FilterOutlined, ForwardOutlined, StopOutlined } from '@ant-design/icons'
import { Button, Form, Input, Tooltip } from 'antd'
import test from '../../../../Assets/MeetOurSpecialists/doc2.jpg'

import { SharedContext } from '../../../../Context/SharedContextProvider/SharedContextProvider';
// import { Link } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { ViewAppointmentContext } from '../../../../Context/ViewAppointmentContextProvider/ViewAppointmentContextProvider';
import AddPrescription from './AddPrescription/AddPrescription';
// import Chat from './Chat/Chat';
import { motion } from "framer-motion"


const ViewAppoinment = () => {
    const {
        navigate,
        capitalizeFirstLetter,
        intlFormatDistance,
        // numberOfDaysBetweenTwoDates,
        formatHumanReadableDate,
        differenceInYears
    } = useContext(SharedContext)

    const {
        showModal_AddPrescription,
        consultationStatus,
        handleOpenPatientsEMR,
        handleStartConsultation,
        handleEndConsultation
    } = useContext(ViewAppointmentContext)

    const [isAtTop, setIsAtTop] = useState(true);

    const handleScroll = () => {
        if (window.scrollY === 0) {
            setIsAtTop(true);
        } else {
            setIsAtTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // const messages = [
    //     { text: 'Hello, world!', time: Date.now(), sender: 'Dr Ben Zima', isSender: true },
    //     { text: 'Hi, User!', time: Date.now(), sender: 'Bot', isSender: false },
    //     { text: 'Hi, User!', time: Date.now(), sender: 'Dr Ben Zima', isSender: true },
    //     { text: 'Hi, User, tell me what you see with the left eye, thats if you see at all', time: Date.now(), sender: 'Dr Ben Zima', isSender: true },
    // ];

    return (
        <div
            className='general_frame_wrapper'


        >
            <div className="inner__">
                <div className={`${!isAtTop ? "heading_bar" : "heading_bar_2"}`}>
                    <motion.h1

                        style={{ display: "flex", alignItems: "center", gap: "1rem" }}

                        initial={{ opacity: 0, translateY: "-10px", }}
                        animate={{ opacity: 1, translateY: "0", }}
                        transition={{ duration: 0.5 }}
                    >
                        <Button icon={<ArrowLeftOutlined />} type='text' size='large' onClick={() => navigate('/appointments')} />
                        <span>
                            Appointment Details
                        </span>
                    </motion.h1>
                    <div className="filter_bar">
                        <div className="search_grp">
                            <Input type="search" name="" id="" placeholder='Search Patient' autoFocus />
                        </div>
                        <div className="filter_btns">
                            <Button icon={<FilterOutlined />} type='text' />
                            <Button icon={<SortAscendingOutlined />} type='text' />
                            <Button icon={<SortDescendingOutlined />} type='text' />
                        </div>
                    </div>
                </div>

                {/*  */}

                <motion.div
                    className="view_appointment_wrapper"

                    initial={{ opacity: 0, translateX: "15px", width: "80%" }}
                    animate={{ opacity: 1, translateX: "0", width: "100%" }}
                    transition={{ duration: 0.5 }}
                >
                    <div className="block_1">
                        <div className="patient_details block_child">
                            <h2 className='block_head'>Patient's Details</h2>
                            <div className="block_body patient_details_body">
                                <div className="left_">
                                    <div className="image_box">
                                        <img src={test} alt="" />
                                    </div>
                                    <h1 className="name_box">{capitalizeFirstLetter('victor obi')}</h1>
                                    <p className="specialization_box">victorobi@gmail.com </p>
                                    {/* <div className="btn_grp">
                                        <Link className='nav_link' to='/dashboard/patients/13'> <EyeOutlined />View Profile</Link>
                                        {
                                            consultationStatus
                                                ?
                                                <button className='start_consultation_btn' type='text' onClick={() => setConsultationStatus(!consultationStatus)}> <ForwardOutlined /> Start Consultation  </button>
                                                :
                                                <button className='end_consultation_btn' type='text' onClick={() => setConsultationStatus(!consultationStatus)}> <StopOutlined /> End Consultation  </button>
                                        }
                                    </div> */}
                                </div>
                                <div className="right_">
                                    <div className="card_grp">
                                        {
                                            [
                                                { label: "gender", value: "female" },
                                                { label: "birthday", value: "2005-02-24" },
                                                { label: "phone number", value: "0706521496" },
                                                { label: "street address", value: "no 5, 3rd avenue, gwarimpa" },
                                                { label: "city", value: "abuja" },
                                                { label: "state", value: "FCT" },
                                                { label: "membership status", value: "active" },
                                                { label: "registered date", value: "1998-05-17" },
                                            ].map((item, index) => (
                                                <div className="card_item" key={index}>
                                                    <div className="label">{item.label}</div>
                                                    {
                                                        // numberOfDaysBetweenTwoDates
                                                        item.label === 'registered date'
                                                            ?
                                                            <div className="value">
                                                                {formatHumanReadableDate(item.value)}
                                                                <br />

                                                                {intlFormatDistance(
                                                                    new Date(item.value),
                                                                    new Date()
                                                                )}
                                                            </div>
                                                            :
                                                            item.label === 'birthday'
                                                                ?
                                                                <div className="value">
                                                                    {formatHumanReadableDate(item.value)}
                                                                    <br />

                                                                    {differenceInYears(
                                                                        new Date(),
                                                                        new Date(item.value)
                                                                    )} years old
                                                                </div>
                                                                :
                                                                <div className="value">
                                                                    {item.value}
                                                                </div>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="patient_notes block_child">
                            <h2 className='block_head'>Patient's Note</h2>
                            <div className=" patient_notes_body">
                                "I feel  pains in my lower stomach, i have tried visiting the toilet for 3 time today, but not feeling better"
                            </div>
                        </div>

                        <div className="patient_submitted_files block_child">
                            <h2 className='block_head'>Patient Submitted Files</h2>
                            <div className="patient_submitted_files_body">
                                <div className="card_grp">
                                    {
                                        [
                                            { label: "malaria test result for victor obi.pdf", url: "" },
                                            { label: "malaria test result for victor obi.pdf", url: "" },
                                            { label: "malaria test result for victor obi.pdf", url: "" },
                                        ].map((item, index) => (
                                            <div className="card_item" key={index}>
                                                <div className="label">{item.label}</div>
                                                <div className="download">
                                                    <Tooltip title='download'>
                                                        <Button icon={<DownloadOutlined />} type='text' />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="doctor_diagnosis block_child">
                            <h2 className='block_head'>Doctor's Diagnosis</h2>

                            <Form name='doctorsDiagnosisForm'>
                                <Form.Item>
                                    <TextArea rows={4} placeholder='Write your diagnosis here'>
                                    </TextArea>
                                </Form.Item>
                            </Form>
                        </div>

                        <div className="drug_prescriptions block_child">
                            <h2 className='block_head'>Drug Prescriptions</h2>
                            <div className="drug_prescription_body">
                                <div className="card_grp">
                                    {
                                        [
                                            { label: "paracentamol", url: "" },
                                            { label: "cotrimoxazole 200mg", url: "" },
                                        ].map((item, index) => (
                                            <div className="card_item" key={index}>
                                                <div className="label">{capitalizeFirstLetter(item.label)}</div>
                                                <div className="download">
                                                    <Tooltip title='remove prescription'>
                                                        <Button icon={<DeleteOutlined />} type='text' danger />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div class='add_prescription_container'>
                                <Tooltip title='add prescription'>
                                    <Button icon={<PlusOutlined />} type='text' onClick={showModal_AddPrescription} >Add Prescription</Button>
                                </Tooltip>
                            </div>
                        </div>

                    </div>

                    <div className="block_2">
                        <div className="sticky_inner__">

                            <div className="btn_grp">
                                <Tooltip title='Open Patients EMR'>
                                    {/* <Link className='nav_link' to='/patients/13'> <EyeOutlined />Open Patient's EMR</Link> */}
                                    <button className='nav_link'
                                        type='text'
                                        onClick={() => handleOpenPatientsEMR(23, 15)}
                                    >
                                        <EyeOutlined /> Open Patient's EMR
                                    </button>
                                </Tooltip>
                                {
                                    consultationStatus
                                        ?
                                        <Tooltip title='Start Consultation'>
                                            <button className='start_consultation_btn'
                                                type='text'
                                                onClick={() => handleStartConsultation(23)}
                                            >
                                                <ForwardOutlined /> Start Consultation
                                            </button>
                                        </Tooltip>
                                        :
                                        <Tooltip title='End Consultation'>
                                            <button className='end_consultation_btn'
                                                type='text'
                                                onClick={() => handleEndConsultation(23)}
                                            > <StopOutlined /> End Consultation  </button>
                                        </Tooltip>
                                }
                            </div>
                            {/* <p>{2 > 1 ? process.env.REACT_APP_NMH_EMR_URL : 6}</p> */}

                        </div>
                    </div>
                </motion.div>

            </div>

            <AddPrescription />
        </div>
    )
}

export default ViewAppoinment
