import React, { useContext } from 'react'
import { SharedContext } from '../../Context/SharedContextProvider/SharedContextProvider'
// import { useNavigate } from 'react-router-dom'

const ProtectedRoute = ({ children }) => {
    const { notification } = useContext(SharedContext)
    // const navigate = useNavigate()

    if (!localStorage.getItem("NMH_LOGIN_TOKEN")) {
        // navigate(`/login`)
        window.location = `/login`
        notification.error({
            message: "Unauthorized Access",
        })
    }

    return (
        <div>
            {children}
        </div>
    )
}

export default ProtectedRoute
