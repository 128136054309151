import React, { useContext, useState, } from 'react';
import './AddPrescription.css'
import { Select, Modal } from 'antd';
import { ViewAppointmentContext } from '../../../../../Context/ViewAppointmentContextProvider/ViewAppointmentContextProvider';
import jsonp from 'fetch-jsonp';
import qs from 'qs';


let timeout;
let currentValue;

const fetch = (value, callback) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    currentValue = value;

    const fake = () => {

        const str = qs.stringify({
            code: 'utf-8',
            q: value,
        });

        jsonp(`https://suggest.taobao.com/sug?${str}`)
            .then((response) => response.json())
            .then((d) => {
                if (currentValue === value) {
                    const { result } = d;
                    const data = result.map((item) => ({
                        value: item[0],
                        text: item[0],
                    }));
                    callback(data);
                }
            });
    };

    if (value) {
        timeout = setTimeout(fake, 300);
    } else {
        callback([]);
    }
};

const prescriptionDosageOptions = [
    {
        value: 'Once daily',
        label: 'Once daily',
    },
    {
        value: 'twice daily',
        label: 'twice daily',
    },
    {
        value: 'trice daily',
        label: 'trice daily',
    },
    {
        value: '4times daily',
        label: '4times daily',
    },
]

const prescriptionDurationOptions = [
    {
        value: '1day',
        label: '1day',
    },
    {
        value: '2days',
        label: '2days',
    },
    {
        value: '3days',
        label: '3days',
    },
    {
        value: '4days',
        label: '4days',
    },
    {
        value: '5days',
        label: '5days',
    },
    {
        value: '6days',
        label: '6days',
    },
    {
        value: '2weeks',
        label: '2weeks',
    },
    {
        value: '3weeks',
        label: '3weeks',
    },
    {
        value: '1month',
        label: '1month',
    },
    {
        value: '2months',
        label: '2months',
    },
    {
        value: '3months',
        label: '3months',
    },
]

const AddPrescription = () => {
    const { isModalOpen_AddPrescription,
        // showModal_AddPrescription,
        handleOk_AddPrescription,
        handleCancel_AddPrescription
    } = useContext(ViewAppointmentContext)

    const [data, setData] = useState([]);
    const [value, setValue] = useState();

    const handleSearch = (newValue) => {
        fetch(newValue, setData);
    };

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Modal
                title="Add Prescription"
                open={isModalOpen_AddPrescription}
                onOk={handleOk_AddPrescription}
                okText='Add'
                onCancel={handleCancel_AddPrescription}
                centered
            >

                <div className="add_prescription_modal">

                    <div className="grp__">
                        <div className='label'> Drug: </div>
                        <Select
                            showSearch
                            value={value}
                            placeholder={`find drug`}
                            style={{ width: "100%" }}
                            defaultActiveFirstOption={false}
                            suffixIcon={null}
                            filterOption={false}
                            onSearch={handleSearch}
                            onChange={handleChange}
                            notFoundContent={null}
                            options={(data || []).map((d) => ({
                                value: d.value,
                                label: d.text,
                            }))}
                        />
                    </div>


                    <div className="grp__">
                        <div className='label'> Dosage: </div>
                        <Select
                            showSearch
                            // value={value}
                            placeholder={`Dosage`}
                            style={{ width: "100%" }}
                            defaultActiveFirstOption={false}
                            suffixIcon={null}
                            filterOption={false}
                            // onSearch={handleSearch}
                            // onChange={handleChange}
                            notFoundContent={null}
                            options={(prescriptionDosageOptions || []).map((d) => ({
                                value: d.value,
                                label: d.label,
                            }))}
                        />
                    </div>



                    <div className="grp__">
                        <div className='label'> Duration: </div>
                        <Select
                            showSearch
                            // value={value}
                            placeholder={`Duration`}
                            style={{ width: "100%" }}
                            defaultActiveFirstOption={false}
                            suffixIcon={null}
                            filterOption={false}
                            // onSearch={handleSearch}
                            // onChange={handleChange}
                            notFoundContent={null}
                            options={(prescriptionDurationOptions || []).map((d) => ({
                                value: d.value,
                                label: d.label,
                            }))}
                        />
                    </div>
                </div>


            </Modal>
        </>
    );
};


export default AddPrescription
