import React, { useContext, useEffect, } from 'react'
import './SignUp.css'
import { UserOutlined, MailOutlined, DashboardFilled } from '@ant-design/icons'
// import { Button, Form, Input, Select } from 'antd'
import { motion } from "framer-motion"
import { SignupContext } from '../../Context/SignupContextProvider/SignupContextProvider'
import Details from './Details/Details'
import Otp from './Otp/Otp'
import Welcome from './Welcome/Welcome'
import { Link } from 'react-router-dom'
// const { Option } = Select;

const Signup = () => {
    const searchParams = new URL(window.location).searchParams
    let paramsObject = {};
    for (let pair of searchParams.entries()) {
        paramsObject[pair[0]] = pair[1];
    }
    const { step } = paramsObject
    // ----------------------------------

    const {
        // navigate,
        // 
        currentStep,
        setCurrentStep,
        // 

    } = useContext(SignupContext)

    useEffect(() => {
        setCurrentStep(Number(step))
    }, [setCurrentStep, step])

    useEffect(() => {
        document.title = `Signup | Neomerit Metro Health`

        return () => {
            document.title = `Dashboard | Neomerit Metro Health`
        }
    }, [])

    return (
        <motion.div
            className='SignUp_wrapper'

            initial={{ opacity: 0, translateY: "-25px", }}
            animate={{ opacity: 1, translateY: "0", }}
            transition={{ duration: 0.5 }}
        >
            <div className="steps_container">
                <div className="inner_2">
                    <div className={currentStep === 1 ? "steps_grp_current" : "steps_grp"}>
                        <div className="line__"></div>
                        <div className="icon_box">
                            <UserOutlined />
                        </div>
                        <div className="label_grp">
                            <div className="big_text">Your details</div>
                            <div className="small_text">complete the fields provided</div>
                        </div>
                    </div>
                    <div className={currentStep === 2 ? "steps_grp_current" : "steps_grp"}>
                        <div className="icon_box">
                            <MailOutlined />
                        </div>
                        <div className="label_grp">
                            <div className="big_text">verify your email</div>
                            <div className="small_text">enter verification code sent to your email</div>
                        </div>
                    </div>
                    <div className={currentStep === 3 ? "steps_grp_current" : "steps_grp"}>
                        <div className="icon_box">
                            <DashboardFilled />
                        </div>
                        <div className="label_grp">
                            <div className="big_text">welcome to neomerit metro health</div>
                            <div className="small_text">get up and running in 2minutes</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="form_container">
                <div className="inner_form_container">
                    {currentStep === 1 && <Details />}
                    {currentStep === 2 && <Otp />}
                    {currentStep === 3 && <Welcome />}
                    <br />
                    <p className='sub_text'>Already have an account? <Link to='/login'>Login as Doctor/Nurse</Link> </p>
                </div>
            </div>
        </motion.div>

    )
}

export default Signup
