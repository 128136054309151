import { message, notification } from "antd";
import { clearCredentialsAndRedirectToLoginPage } from "./clearCredentialsAndRedirectToLoginPage";

notification.config({
  placement: "bottomRight",
});

export function handleHTTPErrors(error) {
  if (error.response.status === 422) {
    message.error(error.response.data.message);

    // console.log(error.response.data.data);

    if (error.response.data.data.length > 0) {
      error.response.data.data.forEach((error) => {
        notification.error({
          message: error,
        });
      });
    }

    // notification.error({
    //   message: error.response.data.message,
    // });
  }
  if (error.response.status === 429) {
    message.error(error.response.data.message);

    notification.error({
      message: error.response.data.message,
    });
  }
  if (error.response.status === 500) {
    message.error(error.response.data.message);

    notification.error({
      message: error.response.data.message,
    });
  }
  if (error.response.status === 400) {
    message.error(error.response.data.message);

    notification.error({
      message: error.response.data.message,
    });
  }
  if (error.response.status === 401) {
    message.error(error.response.data.message);

    notification.error({
      message: error.response.data.message,
    });

    clearCredentialsAndRedirectToLoginPage();
  }
}
