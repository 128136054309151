import React, { useContext, useState } from 'react'
import { motion } from 'framer-motion'
// 
import { Button, Dropdown, Input, Space, Table, } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons';
// import { AppointmentsContext } from '../../../../Context/AppointmentsContextProvider/AppointmentsContextProvider';
import { SharedContext } from '../../../../Context/SharedContextProvider/SharedContextProvider';
import { ExportCSV } from '../../ExportCSV/ExportCSV';
import { CustomFunctionsContext } from '../../../../Context/CustomFunctionsContextProvider/CustomFunctionsContextProvider';
// import { ExportCSV } from '../../ExportCSV/ExportCSV';

// 
// const { confirm } = Modal;
// const { Option } = Select;

const AppointmentsTable = ({ data }) => {

    const {
        navigate,
        formatHumanReadableDate
    } = useContext(SharedContext)

    const {
        formatTime
    } = useContext(CustomFunctionsContext)





    // -------------------------------------------------------------------------

    const [searchTerm, setSearchTerm] = useState('');//for serach input field
    // const [tableLoading, setTableLoading] = useState(false);//use this when fetching radio schedule data from api

    const handleMenuClick = (e, row) => {
        if (e.key === 'viewAppointment') {
            navigate(`/appointments/${row.id}`)
            console.log('view appt', row.id)
            console.log(row)
        }

        if (e.key === 'cancelAppointment') {
            console.log('cancel apt', row.id)
            // handleDelete(row.id)
        }
    };

    const items = [
        {
            label: 'View Appointment',
            key: 'viewAppointment',
        },
        {
            label: 'Cancel',
            key: 'cancelAppointment',
            danger: true
        },
    ];

    const Menu = ({ items, handleMenuClick, handleOpenChange, appointment }) => (
        <Dropdown
            menu={{ items, onClick: (e) => handleMenuClick(e, appointment) }}
            onOpenChange={handleOpenChange}
        >
            <Space>
                <Button icon={<EllipsisOutlined />} size='small' />
            </Space>
        </Dropdown>
    );

    const columns = [
        {
            title: 'Date',
            dataIndex: 'appointment_date',
            key: 'appointment_date',
            // sorter: (a, b) => a.appointment_date > b.appointment_date,
            // width: '10%', // Set the minimum width here
            render: (text, patient) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {formatHumanReadableDate(patient?.appointment_date)}
                </div>
            ),
        },
        // formatHumanReadableDate
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            render: (text, patient) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {formatTime(patient?.time_start)} - {formatTime(patient?.time_end)}
                </div>
            ),
            width: '10%', // Set the minimum width here
        },
        {
            title: 'Patient Full Name',
            dataIndex: 'patientFullName',
            key: 'patientFullName',
            // sorter: (a, b) => a.zone_code > b.zone_code,
            // width: '15%', // Set the minimum width here
            render: (text, patient) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {patient?.first_name} {patient?.last_name}
                </div>
            )
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            // sorter: (a, b) => a.zone_code > b.zone_code,
            // width: '15%', // Set the minimum width here
            render: (text, patient) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {patient?.status}
                </div>
            )
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, appointment) =>
                <Menu
                    items={items}
                    handleMenuClick={handleMenuClick}
                    // handleOpenChange={handleOpenChange}
                    appointmentId={appointment.id}
                    appointment={appointment}
                />,
            width: '10%', // Set the minimum width here
        },
    ];

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };


    const filteredData = data.filter((row) => {
        // Add your logic here to determine if the row matches the search term
        // For example, if you want to filter by 'state':
        return row.first_name.toLowerCase().includes(searchTerm.toLowerCase())
    });


    return (
        <motion.div
            className='table_wrapper'

            initial={{ opacity: 0, translateY: "-10px", scale: "0.9" }}
            animate={{ opacity: 1, translateY: "0", scale: "1" }}
            transition={{ duration: 0.5 }}

            style={{
                width: "98%",
                margin: "auto",
            }}
        >

            <div className="table_controls_wrapper"     >
                <Input.Search
                    className='search_input__'
                    placeholder="Search by name"
                    onChange={handleSearch}
                    autoFocus
                />

                <span className='row_action_grp' >
                    {/* <Tooltip title='Create Zone' placement='left'>
                        <Button type='text' icon={<PlusOutlined />} className='btns__ primary_button'
                        // onClick={showDrawer_create}
                        >
                            Create Zone
                        </Button>
                    </Tooltip> */}
                    {/* <Tooltip title='Upload Excel' placement='left'>
                        <Button type='text' icon={<CloudUploadOutlined />} className='btns__ secondary_button'  >Upload Excel</Button>
                    </Tooltip> */}
                    <ExportCSV csvData={filteredData} fileName='All Appointments' />
                </span>
            </div>

            <Table
                dataSource={filteredData}
                columns={columns}
                rowSelection={true}
                rowKey={user => user.id}
                size='small'
                style={{
                    padding: "0  ",
                    // width: '100%',
                    margin: "auto"
                }}
                sticky
                expandable={true}
                showSorterTooltip={false}
            // showHeader={false}
            // loading={{
            //     // spinning: tableLoading,
            //     // indicator: <Spin size="large" />,
            //     indicator: <Spin indicator={<LoadingOutlined spin />} style={{ scale: '2.5' }} tip='Loading...' size="large" />
            // }}
            // onChange={handleTableChange}
            />
        </motion.div>
    )
}

export default AppointmentsTable
