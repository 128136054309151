import React, { useContext, useEffect, useState } from 'react'
import './style.css'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import classNames from 'classnames';
import { AppstoreOutlined } from '@ant-design/icons'
import { motion } from "framer-motion"
// import { Rate } from 'antd';
import { CustomFunctionsContext } from '../../../Context/CustomFunctionsContextProvider/CustomFunctionsContextProvider';
import { SettingsContext } from '../../../Context/SettingsContextProvider/SettingsContextProvider';


const Dashboard = ({ appState }) => {

    const {
        formatTime,
        formatDate
    } = useContext(CustomFunctionsContext)

    // const { formatHumanReadableDate, navigate, capitalizeFirstLetter } = useContext(SharedContext)

    const { getDoctorDashboard } = useContext(SettingsContext)
    // const { useGetRequest, } = useContext(CustomHooksContext)
    // const { showGlobalLoading, hideGlobalLoading, } = useContext(AppContext);
    const { handleHTTPErrors, } = useContext(CustomFunctionsContext);


    const [isAtTop, setIsAtTop] = useState(true);
    const [value, setValue] = useState(new Date());
    const [dashboard, setDashboard] = useState(null)
    const [appointments, setAppointments] = useState([])
    const [selectedAppointments, setSelectedAppointments] = useState([]);

    const onChange = (date) => {
        setValue(date);
        const appointmentsForDate = appointments.filter(
            (appointment) => new Date(appointment.appointment_date).toDateString() === date.toDateString()
        );
        setSelectedAppointments(appointmentsForDate);
    };



    const handleScroll = () => {
        if (window.scrollY === 0) {
            setIsAtTop(true);
        } else {
            setIsAtTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const appointment = dashboard && dashboard.appointments && JSON.parse(dashboard.appointments).length > 0 && appointments.find(
                (appointment) => new Date(appointment?.appointment_date).toDateString() === date.toDateString()
            );
            if (appointment) {
                return classNames({
                    'tile-upcoming': appointment.status === 'UPCOMING',
                    'tile-cancelled': appointment.status === 'CANCELLED',
                    'tile-completed': appointment.status === 'COMPLETED',
                });
            }
        }
        return null;
    };


    const tileContent = ({ date, view }) => {
        if (view === 'month') {
            const count = appointments.filter(
                (appointment) => new Date(appointment?.appointment_date).toDateString() === date.toDateString()
            ).length;
            return count > 0 ? <div className="appointment-count">{count}</div> : null;
        }
        return null;
    };

    useEffect(() => {
        async function fxn() {
            try {
                // showGlobalLoading();
                const response = await getDoctorDashboard();

                if (response.status === 200) {
                    setDashboard(response?.data?.data)
                }
            } catch (error) {
                console.log(error)
                handleHTTPErrors(error);
            }
        }

        fxn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (dashboard && dashboard.appointments && JSON.parse(dashboard.appointments).length > 0) {
            setAppointments(JSON.parse(dashboard.appointments));
        }
    }, [dashboard])




    return (
        <div
            className='general_frame_wrapper'
        >
            <div
                className={`${!isAtTop ? "heading_bar" : "heading_bar_2"}`}
            >
                <motion.h1
                    style={{ display: "flex", alignItems: "center", gap: "1rem" }}

                    initial={{ opacity: 0, translateY: "-10px", }}
                    animate={{ opacity: 1, translateY: "0", }}
                    transition={{ duration: 0.5 }}
                >
                    {/* <Button icon={<ArrowLeftOutlined />} type='text' size='large' onClick={() => navigate('/appointments')} /> */}
                    <span>
                        <AppstoreOutlined /> Dashboard
                    </span>
                </motion.h1>
            </div >

            <motion.div
                className='Dashboard_wrapper'
                initial={{ opacity: 0, translateX: "15px", width: "80%" }}
                animate={{ opacity: 1, translateX: "0", width: "100%" }}
                transition={{ duration: 0.5 }}
            >
                <div className="Dashboard_wrapper_inner__">
                    <div className="banner__">
                        <div className="banner__inner">
                            <h1 className="banner__inner__title">Hi,  {appState?.prefix ?? "--"} {appState?.first_name ?? "--"}   {appState?.last_name ?? "--"}</h1>
                        </div>
                        <p className="banner__inner__sub_title">Welcome to your dashboard, last seen on {formatDate(appState?.last_seen)}, {appState?.last_seen?.split("T")[1].split(".")[0]} </p>
                    </div>

                    <div className="row_grp__">
                        <div className="card_grp">

                            <div className="card__">
                                <div className="card__inner">
                                    <div className="card__inner__label"> {dashboard?.total_appointments ?? "--"} </div>
                                    <div className="card__inner__title total">Total Appointments</div>
                                </div>
                            </div>

                            <div className="card__">
                                <div className="card__inner">
                                    <div className="card__inner__label">  {dashboard?.upcoming_appointments ?? "--"} </div>
                                    <div className="card__inner__title upcoming">Upcoming Appointments</div>
                                </div>
                            </div>

                            <div className="card__">
                                <div className="card__inner">
                                    <div className="card__inner__label"> {dashboard?.cancelled_appointments ?? "--"} </div>
                                    <div className="card__inner__title cancelled">Cancelled Appointments</div>
                                </div>
                            </div>

                            <div className="card__">
                                <div className="card__inner">
                                    <div className="card__inner__label"> {dashboard?.completed_appointments ?? "--"} </div>
                                    <div className="card__inner__title completed">Completed Appointments</div>
                                </div>
                            </div>

                            <div className="card__">
                                <div className="card__inner">
                                    <div className="card__inner__label"> {dashboard?.pending_appointments ?? "--"} </div>
                                    <div className="card__inner__title pending">Pending Appointments</div>
                                </div>
                            </div>

                            <div className="card__">
                                <div className="card__inner">
                                    <div className="card__inner__label"> {dashboard?.missed_appointments ?? "--"} </div>
                                    <div className="card__inner__title missed">Missed Appointments</div>
                                </div>
                            </div>

                        </div>


                        <div className="calendar_wrapper">
                            <Calendar
                                onChange={onChange}
                                value={value}
                                tileClassName={tileClassName}
                                tileContent={tileContent}
                            />
                        </div>

                        <div className="appointments_list">
                            <h2>Appointments for {value.toDateString()}</h2>
                            <div className="appointment_item_grp__">
                                {selectedAppointments.length > 0 ? (
                                    selectedAppointments.map(appointment => (
                                        <table key={appointment?.id} className="appointment_item">
                                            <tbody>
                                                <tr>

                                                </tr>
                                                <p><strong>Patient:</strong>  {appointment?.patient_name} </p>
                                                <p><strong>Time:</strong> {formatTime(appointment?.time_start)} - {formatTime(appointment?.time_end)}</p>
                                                <p><strong>Reason:</strong> {appointment?.reason}</p>
                                            </tbody>
                                        </table>
                                    ))
                                ) : (
                                    <p>No appointments for this date.</p>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="row_grp__">
                        <div className="card_grp2">
                            <div className="card__">
                                <div className="card__inner">
                                    <div className="card__inner__label"> {dashboard?.overall_rating}
                                        {/* <Rate disabled defaultValue={dashboard?.overall_rating} style={{ color: "var(--bgRed)" }} /> */}
                                    </div>
                                    <div className="card__inner__title">Overall Rating</div>
                                </div>
                            </div>
                            <div className="card__">
                                <div className="card__inner">
                                    <div className="card__inner__label"> {dashboard?.total_reviews ?? "--"} </div>
                                    <div className="card__inner__title">Total Reviews</div>
                                </div>
                            </div>
                            <div className="card__">
                                <div className="card__inner">
                                    <div className="card__inner__label"> {dashboard?.total_reviewing_patients ?? "--"} </div>
                                    <div className="card__inner__title">Total Reviewing Patients</div>
                                </div>
                            </div>
                            <div className="card__">
                                <div className="card__inner">
                                    <div className="card__inner__label"> {dashboard?.total_patients ?? "--"} </div>
                                    <div className="card__inner__title">Total Patients</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </motion.div >
        </div >
    )
}

export default Dashboard
