import React, { useEffect, useState } from 'react'
import { MessageOutlined } from '@ant-design/icons'
import { motion } from "framer-motion"


const Chats = () => {

    const [isAtTop, setIsAtTop] = useState(true);

    const handleScroll = () => {
        if (window.scrollY === 0) {
            setIsAtTop(true);
        } else {
            setIsAtTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            className='general_frame_wrapper'
        >
            <div className={`${!isAtTop ? "heading_bar" : "heading_bar_2"}`}>
                <motion.h1
                    style={{ display: "flex", alignItems: "center", gap: "1rem" }}

                    initial={{ opacity: 0, translateY: "-10px", }}
                    animate={{ opacity: 1, translateY: "0", }}
                    transition={{ duration: 0.5 }}
                >
                    {/* <Button icon={<ArrowLeftOutlined />} type='text' size='large' onClick={() => navigate('/appointments')} /> */}
                    <span>
                        <MessageOutlined /> Chats
                    </span>
                </motion.h1>
            </div >

            <motion.div
                initial={{ opacity: 0, translateX: "15px", width: "80%" }}
                animate={{ opacity: 1, translateX: "0", width: "100%" }}
                transition={{ duration: 0.5 }}
            >
                <h1>chats</h1>
            </motion.div>
        </div>
    )
}

export default Chats
