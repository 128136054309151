import React, { useContext, useRef, useState, } from 'react';
import { Button, Drawer, Image, Space } from 'antd';
import { UndoOutlined, CameraOutlined } from '@ant-design/icons'
import { ProfileContext } from '../../../../Context/ProfileContextProvider/ProfileContextProvider';


const EditAvatar = () => {
    const {
        // createData,
        setCreateData,
        // 
        open_create,
        // showDrawer_create,
        onClose_create,
        // 
        handleUpload
    } = useContext(ProfileContext)

    const [imagePreview, setImagePreview] = useState(null)
    const inputFileRef = useRef(null);

    const handleUploadFromDevice = () => {
        inputFileRef.current.click();
    }

    const handleResetImagecapture = () => {
        setCreateData((prev) => ({
            ...prev,
            avatar: null,
        }))
        setImagePreview(null)
    }

    function handleImageChange(e) {
        if (e.target.files) {
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append('avatar', file);

            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                setCreateData((prev) => ({ ...prev, avatar: formData }));
                setImagePreview(event.target.result)
            };

            fileReader.readAsDataURL(e.target.files[0]);
        }
    }

    return (
        <div className="">
            <Drawer
                title="Change Profile Photo"
                width={520}
                onClose={onClose_create}
                open={open_create}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                className='create_drawer_wrapper'
                extra={
                    <Space>
                        <Button onClick={onClose_create} className='secondary_btn' >Cancel</Button>
                        <Button onClick={handleUpload} type="text" className='main_btn' >
                            Upload
                        </Button>
                    </Space>
                }
            >
                <div className=""
                    style={{ margin: "auto", display: "grid", placeContent: "center", placeItems: "center" }}
                >

                    {
                        imagePreview
                            ?
                            <div className=""
                                style={{
                                    width: "40%", margin: "auto", overflow: "hidden", display: "grid", placeContent: "center", placeItems: "center", gap: "1rem"
                                }}
                            >
                                <Image src={imagePreview} style={{ margin: "auto", }} />
                                <Button type='text' icon={<UndoOutlined />} onClick={handleResetImagecapture}>Reset</Button>
                            </div>
                            :
                            <>
                                <Button type='text' className='capture_btn' icon={<CameraOutlined />} onClick={handleUploadFromDevice}>Upload Profile Photo</Button>
                                <input
                                    type="file"
                                    // accept only jpeg, jpg, png images
                                    accept="image/*"
                                    name="avatar"
                                    ref={inputFileRef}
                                    onChange={handleImageChange}
                                    style={{ display: "none" }}
                                />
                            </>
                    }
                </div>
            </Drawer>
        </div>
    )
}

export default EditAvatar
