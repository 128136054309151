import React, { createContext } from 'react'
import useGetRequest from './useGetRequest'

export const CustomHooksContext = createContext()

const CustomHooksContextProvider = ({ children }) => {

    const values = {
        name: 'Custom Hooks',
        description: "Custom Hooks are a way to reuse stateful logic between components. ",
        // 
        useGetRequest,
     

    }
    return (
        <CustomHooksContext.Provider value={values}>
            {children}
        </CustomHooksContext.Provider>
    )
}

export default CustomHooksContextProvider
