const breadcrumbsRoutes = [
  { path: "/", breadcrumb: "Home" },
  { path: "/profile", breadcrumb: "Profile" },
  { path: "/profile/edit", breadcrumb: "Edit Profile" },
  { path: "/chats", breadcrumb: "Chats" },
  { path: "/appointments", breadcrumb: "Appointments" },
  // { path: "/dashboard", breadcrumb: "Dashboard" },
];

export default breadcrumbsRoutes;
