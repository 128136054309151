import React, { createContext, useContext } from 'react'
import { SharedContext } from '../SharedContextProvider/SharedContextProvider'
import axios from 'axios'

export const SettingsContext = createContext()

const SettingsContextProvider = ({ children }) => {
    const { CONSTANTS } = useContext(SharedContext)

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("NMH_LOGIN_TOKEN")}`,
        }
    }
    // configuration to send multipart form data
    const configFormData = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem("NMH_LOGIN_TOKEN")}`
        }
    }
    // ---------------------------------------------------------------------

    async function createUser(body) {
        const response = await axios.post(`${CONSTANTS.BACKEND_URL}/users/create`, body)
        return response
    }
    async function verifyEmail(body) {
        const response = await axios.post(`${CONSTANTS.BACKEND_URL}/users/verify-account`, body)
        return response
    }

    async function login(body) {
        const response = await axios.post(`${CONSTANTS.BACKEND_URL}/users/login`, {
            email: body.email,
            password: body.password
        }, config)
        return response
    }

    async function getSpecializations() {
        const response = await axios.get(`${CONSTANTS.BACKEND_URL}/specializations`, config)
        return response
    }

    async function getMyProfile() {
        const response = await axios.get(`${CONSTANTS.BACKEND_URL}/users/my-profile`, config)
        return response
    }

    async function changeProfilePhoto(body) {
        const response = await axios.post(`${CONSTANTS.BACKEND_URL}/users/change-profile-photo`, body, configFormData)
        return response
    }

    async function getDoctorDashboard() {
        const response = await axios.get(`${CONSTANTS.BACKEND_URL}/doctors/my-dashboard`, config)
        return response
    }

    async function getDoctorProfile() {
        const response = await axios.get(`${CONSTANTS.BACKEND_URL}/doctors/my-profile`, config)
        return response
    }

    async function getDoctorAppointments() {
        const response = await axios.get(`${CONSTANTS.BACKEND_URL}/appointments/doctor`, config)
        return response
    }

    // logout
    async function logout() {
        const response = await axios.post(`${CONSTANTS.BACKEND_URL}/users/logout`, {}, config)
        return response
    }
    // ---------------------------------------------------------------------
    async function updateProfileGrpA(body) {
        const response = await axios.put(`${CONSTANTS.BACKEND_URL}/doctors/a`, body, config)
        return response
    }
    async function updateProfileGrpB(body) {
        const response = await axios.put(`${CONSTANTS.BACKEND_URL}/doctors/b`, body, config)
        return response
    }
    async function updateProfileGrpC(body) {
        const response = await axios.put(`${CONSTANTS.BACKEND_URL}/doctors/c`, body, config)
        return response
    }
    async function updateProfileGrpD(body) {
        const response = await axios.put(`${CONSTANTS.BACKEND_URL}/doctors/d`, body, config)
        return response
    }
    async function updateProfileGrpE(body) {
        const response = await axios.put(`${CONSTANTS.BACKEND_URL}/doctors/e`, body, config)
        return response
    }
    async function updateProfileGrpF(body) {
        const response = await axios.put(`${CONSTANTS.BACKEND_URL}/doctors/f`, body, config)
        return response
    }
    // ---------------------------------------------------------------------





    const values = {
        createUser,
        verifyEmail,
        // 
        login,
        logout,
        getDoctorProfile,
        getDoctorDashboard,
        getDoctorAppointments,
        // 
        getMyProfile,
        changeProfilePhoto,
        // 
        getSpecializations,
        // 
        updateProfileGrpA,
        updateProfileGrpB,
        updateProfileGrpC,
        updateProfileGrpD,
        updateProfileGrpE,
        updateProfileGrpF,
        // 
    }
    return (
        <SettingsContext.Provider value={values}>
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsContextProvider
