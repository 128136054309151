import React from 'react'
import { useLocation, Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import breadcrumbsRoutes from './breadcrumbsRoutes'

const Breadcrumbs = () => {
    const location = useLocation();
    const breadcrumbs = useBreadcrumbs(breadcrumbsRoutes);
    // const breadcrumbs = []

    return (
        <nav style={{
            display: "flex",
            gap: ".2rem",
            textDecoration: "none",
            fontSize: "12px",
            padding: "0"
        }}>
            {breadcrumbs.map(({ breadcrumb, match }, index) => (
                <Link
                    key={match.pathname}
                    to={match.pathname}
                    className={location.pathname === match.pathname ? "active" : ""}
                    style={{
                        textDecoration: "none",
                        color: location.pathname === match.pathname ? "var(--bgRed)" : "var(--textGrey)",
                        fontWeight: location.pathname === match.pathname ? "500" : "",
                        // fontStyle: "italic"
                    }}
                >
                    {breadcrumb} {index < breadcrumbs.length - 1 ? "/" : ""}
                </Link>
            ))}
        </nav>
    )
}

export default Breadcrumbs
