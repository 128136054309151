import React, { createContext, useContext, useState } from 'react'
import { SharedContext } from '../SharedContextProvider/SharedContextProvider'
import { AppContext } from '../AppContext/AppContextProvider'
import { SettingsContext } from '../SettingsContextProvider/SettingsContextProvider'
import { CustomFunctionsContext } from '../CustomFunctionsContextProvider/CustomFunctionsContextProvider'
import { message } from 'antd'

export const SignupContext = createContext()

const SignupContextProvider = ({ children }) => {
    const { navigate, notification } = useContext(SharedContext)

    const { showGlobalLoading, hideGlobalLoading, } = useContext(AppContext);
    const { createUser, verifyEmail } = useContext(SettingsContext);
    const { handleHTTPErrors, } = useContext(CustomFunctionsContext);
    // ------------------------------------


    const [currentStep, setCurrentStep] = useState(null)
    const [userType, setUserType] = useState(null)



    const [createData, setCreateData] = useState({
        prefix: null,
        first_name: null,
        last_name: null,
        // middle_name: null,
        gender: null,
        email: null,
        phone_1: null,
        // phone_2: null,
        password: null,
        // avatar: null,
        where_did_you_hear_about_us: null,
        user_type: "DOCTOR",
    })

    async function handleSignup(e) {
        if (Object.values(createData).includes(null)) {
            notification.error({
                message: "All fields are required!",
            })
            return false
        }

        // console.log(createData)

        try {
            showGlobalLoading();
            const response = await createUser(createData);

            // console.log(response)

            if (response.status === 201) {
                message.success(response.data.message);

                // notify success
                notification.success({
                    message: "User Created Successfully",
                    description: "User created successfully",
                })

                localStorage.setItem('userData', JSON.stringify(response.data.data))

                navigate(`/signup?step=2`)
            }
            hideGlobalLoading();
        } catch (error) {
            console.log(error)
            hideGlobalLoading();
            handleHTTPErrors(error);
        }
    }

    async function handleVerifyEmail(e) {
        if (e.otp_value) {
            try {
                showGlobalLoading();
                const userData = JSON.parse(localStorage.getItem('userData'))
                // console.log(userData.id)
                const response = await verifyEmail({
                    userId: userData.id,
                    userType: userData.user_type,
                    // rawPwd: createdUser.rawPwd,
                    otp: e.otp_value,
                    // email: userData.email
                });

                // console.log(response)

                if (response.status === 200 && response.data.verified === true) {
                    message.success(response.data.message);

                    // notify success
                    notification.success({
                        message: "Account Verified Successfully",
                        description: "Account Verified Successfully",
                    })

                    localStorage.removeItem("userData")
                    // localStorage.setItem("NMH_LOGIN_TOKEN", response.data.login_token)

                    // navigate(`/signup?step=3`)
                    navigate(`/login`)

                }
                hideGlobalLoading();
            } catch (error) {
                console.log(error)
                hideGlobalLoading();
                handleHTTPErrors(error);
            }
        }
    }

    // console.log(`${process.env.REACT_APP_NMH_DOCTOR_URL}/auth/${localStorage.getItem("NMH_LOGIN_TOKEN")}`)

    function handleNavigateToDashboard() {
        navigate(`/`)
    }

    const values = {
        // navigate,
        // 
        currentStep,
        setCurrentStep,
        // 
        createData, setCreateData,
        // 
        userType,
        setUserType,
        // 
        handleSignup,
        handleVerifyEmail,
        handleNavigateToDashboard,
    }
    return (
        <SignupContext.Provider value={values}>
            {children}
        </SignupContext.Provider>
    )
}

export default SignupContextProvider
