import { createContext, useState } from 'react'


export const AppContext = createContext()

const AppContextProvider = ({ children }) => {

    const [appState, setAppState] = useState({
        isLoading: false,
    })

    function showGlobalLoading() {
        setAppState({ ...appState, isLoading: true })
    }

    function hideGlobalLoading() {
        setAppState({ ...appState, isLoading: false })
    }



    /**
     * 
     */
    const values = {
        appState,
        setAppState,
        showGlobalLoading,
        hideGlobalLoading,
    }
    return (
        <AppContext.Provider value={values}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContextProvider
